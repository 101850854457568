import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import {useNavigate } from "react-router-dom";
import { FaFilePdf  } from "react-icons/fa";
import { Card, Button } from 'react-bootstrap';
import { getBaseApiUrl } from '../../utils/utility'

const UploadAdditional = ({me}) => {
   
    const [file, setFile] = useState(null);
    const [cookies, setCookie] = useCookies(['token']);
    const [document, setDocument] = useState('');
    const [show, setShow] = useState(true);
    const [message, setMessage] = useState('Please upload additional documents if needed');
    const [colorMessage, setColorMessage] = useState('#cccccc');

    // const cardBgColor = '#0a262c';
    const cardBgColor = '#001d23';
    const orangeColor = '#ff6f0f';  // this is ornage color
    const deepOrangeColor = '#CC5500';  // this is ornage color
    const headerBgColor = '#0a262c';  // this is footer-color color
    const footerBgColor = '#0a262c';    // this is footer-color color
    const textColor = '#E2DFD2';

    const cardStyle = {
        position: "absolute",
        bottom: "0",
        left: "0",
        width: "100%",
        backgroundColor: "#f0f0f0",
        padding: "10px",
    };

    const hiddenTextStyle = {
        display: 'none', // Hide the text by default
    };

    
    const handleFileChange = (documentType, event) => {
        setFile(event.target.files[0]);
        setDocument(documentType);
    };

    const showHide = () => {
        setShow(true);
        setMessage("Please upload additional documents if needed");
        setColorMessage('#cccccc');
        
        
    }
  const handleFormSubmit = async (documentType, event) => {
    event.preventDefault();
    // alert(documentType)

    const program = 'mbbs'
    if (!file) {
      alert('Please select a file');
      return;
    }
    // console.log(document);
    const formData = new FormData();
    formData.append('file', file);
    // formData.append('documentType', document);

    try {
        const headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${cookies['token']}`
          }
        const response = await axios.post(`${getBaseApiUrl()}/upload/${program}/${document}`, formData, {
        headers: headers,
      });

      alert('SUCCESS :: File upload successful.');
      setFile(null); // Reset the file state after successful upload
      setShow(false)
      setMessage("Your file uploaded successfully, click next if you want to upload another one")
      // nextComponent();
      setColorMessage('#AAFF00');
    } catch (error) {
      setMessage(error.message)
      console.error('File upload failed:', error.message);
      alert('ERROR :: File upload failed');
      setColorMessage('red');
    }
  };


  return (
        
          <Card style={{backgroundColor: cardBgColor, width: '38rem', color: textColor, display: 'block'}}>
                <Card.Header style={{backgroundColor: headerBgColor}}>Additional Upload ?</Card.Header>
                <Card.Body>
                <Card.Title><br/></Card.Title>
                <Card.Subtitle className="mb-5" style={{color: colorMessage}}>{message}</Card.Subtitle>
                <Card.Text>
                    {
                        show ? (<form onSubmit={(event) => handleFormSubmit("application", event)}>
                        <label><input type="file" name="file" onChange={(event) => handleFileChange("application", event)} /></label>
                        <Button type="submit" style={{backgroundColor: deepOrangeColor,  color: '#E2DFD2'}}>Submit</Button>
                        </form>) 
                        : 
                        (<p> Please click <Button type="button" onClick={showHide} style={{ backgroundColor: deepOrangeColor, color: '#E2DFD2' }}>NEXT</Button> to upload more document</p>)
                    } 
                     
                       
                </Card.Text>
                
                </Card.Body>
                <Card.Footer style={{backgroundColor: footerBgColor}}>If requested or required, please upload any additional documents</Card.Footer>
                
                </Card>
  )
    };

export default UploadAdditional;
