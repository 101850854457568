import React from 'react';
// import axios from 'axios';
// import { useCookies } from 'react-cookie';
// import {useNavigate } from "react-router-dom";
import { FaFilePdf  } from "react-icons/fa";


import ShowHideComponents from './ApplicationComponent';
import UploadAdditional from './UploadAdditional';

const MBBSApplicationContent = ({me, isLoggedIn}) => {
  let publicUrl = process.env.PUBLIC_URL + '/';
  const contractAgreementPdf = process.env.PUBLIC_URL + '/assets/images/resources/TTWF-MBBS-Scholarship-Contract-Agreement.pdf';
  const policyAcknowledgementPdf = process.env.PUBLIC_URL + '/assets/images/resources/Acknowledgement-of-Overview-and-Policy-Package.pdf';
  const scholarshipApplicationPdf = process.env.PUBLIC_URL + '/assets/images/resources/TTWF-Scholarship-2024-Application-Word-Document.pdf';
  const parentConsentPdf = process.env.PUBLIC_URL + '/assets/images/resources/Parent-Consent.pdf';
  const appStatus = me.program[0].applicationStatus[me.program[0].applicationStatus.length -1 ].status;
  const reason = me.program[0].applicationStatus[me.program[0].applicationStatus.length -1 ].reason;
  return (
    <>
      <section className="contact-page" >
        <div className="contact-page-bg" style={{ backgroundImage: 'url(' + publicUrl + 'assets/images/backgrounds/contact-page-bg.jpg)' }}></div>
        <div className="container">
          <div className='row section-title' style={{marginTop: -80, color: "red"}}>
            <div className='col' style={{textAlign: "left"}} >
              {me && me.firstName && <span><b>Welcome, {me.firstName}!</b></span>}
              </div>
              <div className='col' style={{textAlign: "left", color: 'black'}} >
              {me && me.program && me.program[0].applicationStatus && appStatus && (
                <span>Your Application Status: <b>{appStatus}</b> <br />
                {reason && (<span>Reason: <b>{reason}</b></span>)}
                <br />
                
                </span>
                
              )}
              </div>
             
          </div>
          <div className='row' style={{marginTop: -20}}>
            <div className='section-title__tagline' style={{textAlign: "left", }} >
                  MBBS Application
              </div>
              <div className='line'>
                   <hr />
              </div>
          </div>
           <div className='row' style={{paddingBottom: 5}}>
            <div className='col'><b><u>Downloads</u></b></div>
            <div className='col'><b><u>Application Upload Instructions:</u></b></div>
           </div>
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <div className="contact-page__left">
               
                <div className="contact-page__form ">
                <div className="row">
                      <div className="col-xl-12">
                        <div className="comment-form__input-box">
                            Download and complete these documents to apply 
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="comment-form__input-box">
                            <a href={parentConsentPdf} download="Parent-Consent.pdf" className="btn btn-outline-primary">
                                <FaFilePdf /> Parent-Consent
                            </a>
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="comment-form__input-box">
                            <a href={contractAgreementPdf} download="TTWF-MBBS-Scholarship-Contract-Agreement.pdf" className="btn btn-outline-primary">
                                <FaFilePdf /> TTWF-MBBS-Scholarship-Contract-Agreement
                            </a>
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="comment-form__input-box">
                            <a href={policyAcknowledgementPdf} download="Acknowledgement-of-Overview-and-Policy-Package.pdf" className="btn btn-outline-primary">
                                <FaFilePdf /> Acknowledgement-of-Overview-and-Policy-Package
                            </a>
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="comment-form__input-box">
                            <a href={scholarshipApplicationPdf} download="TTWF-Scholarship-2024-Application-Word-Document.pdf" className="btn btn-outline-primary">
                                <FaFilePdf /> TTWF-Scholarship-2024-Application-Word-Document
                            </a>
                        </div>
                      </div>

                      <div style={{textAlign: "left", color: 'black'}} >
                        Need Help?: Email @ <b>ttwfus@gmail.com</b> or call @ <b>+1-717-836-0058</b>
                        </div>
                       
                       
                    </div>
                
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
                          <div>
                          <span style={{color: "red"}}><b>You can upload a maximum of 4 documents</b></span>
                                <ol>
                                <li>Parent-Consent: Download, fill, and upload as a signed PDF</li>
                                <li>Contract: Download, fill, and upload as a signed PDF</li>
                                <li>Package Acknowledgment: Download, fill, and upload as a signed PDF</li>
                                <li>Application: Download, merge additional documents (Essay, proof documents, etc.), and upload as a single PDF</li>
                              </ol>
                          </div>
                    
             
              <br />
              {isLoggedIn && me 
                          && me.program 
                          && me.program[0].applicationStatus 
                          && (appStatus ==='not-applied')
                          && <ShowHideComponents me={me} />
                }

                {isLoggedIn && me 
                          && me.program 
                          && me.program[0].applicationStatus 
                          && (appStatus !=='not-applied')
                          && <UploadAdditional me={me} />
                }

              {!isLoggedIn && <div style={{color: "red"}}>You must <a href='/login'>login</a> to upload the application</div>}
              
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MBBSApplicationContent;
