import React from 'react';
import { Link } from 'react-router-dom';

export default class FeatureTwo extends React.Component {
    render(){
        return (
            <>
                <section className="feature-one">
                    <div className="container">
                        <div className="row">

                            <div className="col-xl-4 col-lg-4 wow fadeInLeft" data-wow-delay="100ms">
                                {/* Feature One Single */}
                                <div className="feature-one__single">
                                    <div className="feature-one__top">
                                        <div className="feature-one__icon">
                                            <span className="icon-donation-1"></span>
                                        </div>
                                        <h3 className="feature-one__title"><Link to={process.env.PUBLIC_URL + `/about`}>Medical Education Program</Link></h3>
                                    </div>
                                    <p className="feature-one__text">TTWF aims to sponsor financially deprived, ambitious individuals globally for MBBS, covering tuition, books, travel, and living expenses.</p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="200ms">
                                {/* Feature One Single */}
                                <div className="feature-one__single">
                                    <div className="feature-one__top">
                                        <div className="feature-one__icon">
                                            <span className="icon-heart"></span>
                                        </div>
                                        <h3 className="feature-one__title"><Link to={process.env.PUBLIC_URL + `/about`}>Youth empowerment Program</Link></h3>
                                    </div>
                                    <p className="feature-one__text">TTWF empowers global youth in diverse sectors for community development, emphasizing self-sustainability, entrepreneurship, and regional progress.</p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 wow fadeInRight" data-wow-delay="300ms">
                                {/* Feature One Single */}
                                <div className="feature-one__single">
                                    <div className="feature-one__top">
                                        <div className="feature-one__icon">
                                            <span className="icon-help"></span>
                                        </div>
                                        <h3 className="feature-one__title"><Link to={process.env.PUBLIC_URL + `/about`}>Sanskrit Education Program</Link></h3>
                                    </div>
                                    <p className="feature-one__text">
                                    TTWF pioneers Sanskrit education sponsorship, promoting inclusivity and diversity in India/Nepal with a fair application and selection process.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </>
        )
    }
}