import React, { useState, useRef } from 'react';
import axios from 'axios';
// import {useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { getBaseApiUrl, validateEmail} from '../../utils/utility'

const RegisterForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    username: '',
    password: '',
    captchaValue: null,
  });
  
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  // const navigate = useNavigate ();
  const recaptchaRef = useRef();
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    // Basic validation, you can add more complex validation as needed
    if (!validateEmail(formData.email)) {
      setErrorMessage('Invalid Email');
      return false; 
    }
   
    if (!formData.firstName || !formData.lastName || !formData.email || !formData.username || !formData.password) {
      setErrorMessage('All fields are required.');
      return false;
    }

    if (!formData.captchaValue) {
      setErrorMessage('Captcha is missing');
      return false;
    }
    return true;
  };

  const handleCaptchaChange = (value) => {
    setFormData((prevData) => ({ ...prevData, captchaValue: value }));
  };


  const handleRegister = async (e) => {
    e.preventDefault();
    setSuccessMessage('');
    setErrorMessage('');

    if (!validateForm()) {
      recaptchaRef.current.reset();
      setSuccessMessage('');
      return;
    }

    // let  apiUrlBase = process.env.API_URL_DEV; 
    //     const environ = process.env.ENVIRONMENT
    //     if (environ === 'prod') {
    //         apiUrlBase = process.env.API_URL_PROD; 
    //     }

    try {
      const response = await axios.post(`${getBaseApiUrl()}/register`, formData);

      // Handle successful registration, e.g., redirect or show a success message
      // console.log('Registration successful!', response.data);
      setSubmitted(true);
      setSuccessMessage(response.data.message);
      setErrorMessage('');
      recaptchaRef.current.reset(); // Reset reCAPTCHA on successful submission 
      // navigate('/login')
    } catch (error) {
      if (error.response && error.response.status === 409) {
          // HTTP status code 409 indicates a conflict, likely meaning the user already exists
          setErrorMessage(error.response.data.message);
      } else {
        console.error('Registration failed:', error.message);
        setErrorMessage('Registration failed. Please try again.'); // Set the error message

      }
      recaptchaRef.current.reset(); 
    }
  };
  let publicUrl = process.env.PUBLIC_URL + '/';
  return (
    <>
      <section className="contact-page">
      <div className="contact-page-bg" style={{ backgroundImage: 'url(' + publicUrl + 'assets/images/backgrounds/contact-page-bg.jpg)' }}></div>
        <div className="container">
          
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <div className="contact-page__left">
                <div className="section-title text-left">
                  <span className="section-title__tagline">Register</span>
                </div>
                <div className="contact-page__form">
                {errorMessage && <div style={{border:'1px solid red', height:'50px', padding: '10px', marginBottom: '15px', marginTop: '-35px', color:'red'}}>
                                        <b>{errorMessage}</b>
                                    </div>}
                {successMessage && <div style={{border:'1px solid green', height:'50px', padding: '10px', marginBottom: '15px', marginTop: '-35px', color:'green'}}>
                    <b>{successMessage}</b>
                </div>}



                  <form onSubmit={handleRegister} className="comment-one__form register-form-validated" noValidate>
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="comment-form__input-box">
                          <input type="text" placeholder="First Name" name="firstName" value={formData.firstName} onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="comment-form__input-box">
                          <input type="text" placeholder="Last Name" name="lastName" value={formData.lastName} onChange={handleChange} />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="comment-form__input-box">
                          <input type="email" placeholder="Email" name="email" value={formData.email} onChange={handleChange} />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="comment-form__input-box">
                          <input type="text" placeholder="Username" name="username" value={formData.username} onChange={handleChange} />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="comment-form__input-box">
                          <input type="password" placeholder="Password" name="password" value={formData.password} onChange={handleChange} />
                        </div>
                      </div>
                    </div>


                    <ReCAPTCHA
                          sitekey="6LejiGEpAAAAAHgnoK3Vy6aSzt4EQVbiYmT4G1Ql"
                          onChange={handleCaptchaChange}
                          ref={recaptchaRef}
                        />

                    <div className="row">
                      <div className="col-xl-12">
                        <div className="comment-form__btn-box">
                          <button type="submit" className="thm-btn comment-form__btn" disabled={submitted} >Register</button>
                          <span style={{padding: 50}}>Already have an account? <a href='/login'>Login</a></span>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            
            <div className="col-xl-6 col-lg-6">
              <div className="contact-page__right">
                <div className="contact-page__img">
                  <img src={publicUrl + "assets/images/resources/contact-page-img-1.jpg"} alt="" />
                  <div className="contact-page__img-shape">
                    <img src={publicUrl + "assets/images/shapes/contact-page-img-shape.png"} alt="" />
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    </>
  );
};

export default RegisterForm;
