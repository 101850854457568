import React from 'react';
import HeaderOne from '../common/header/HeaderOne';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import DonateCancel from '../components/donation/DonateCancel';
import FooterTwo from '../common/footer/FooterTwo';

const DonationCancel = () => {
    return (
        <>
            <HeaderOne />
            <Breadcrumb 
                heading="Donate Now"
                currentPage="Donate Now" 
            />
            <DonateCancel />
            <FooterTwo />
        </>
    )
}

export default DonationCancel;