import React from 'react';

export default class ContactMap extends React.Component {
    render(){
        return (
            <>
                <section className="contact-page-google-map">
                    <iframe
                        title="Address"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3044.260193859029!2d-76.96033222259436!3d40.269969971463745!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c8c3a5ef698bfd%3A0xc3cfdf45bdb69954!2s1016%20Saffron%20Dr%2C%20Mechanicsburg%2C%20PA%2017050!5e0!3m2!1sen!2sus!4v1705976213853!5m2!1sen!2sus"
                        className="contact-page-google-map__one" ></iframe>

                </section>
            </>
        )
    }
}