import { useState, useEffect } from 'react';
import HeaderOne from '../common/header/HeaderOne';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { getBaseApiUrl } from '../utils/utility'
// import {useNavigate } from "react-router-dom";

import FooterTwo from '../common/footer/FooterTwo';

const VerifyEmailComponent = () => {
    const { token } = useParams();
    const [validationResultError, setValidationResultError] = useState('');
    const [validationResultSuccess, setValidationResultSuccess] = useState('');
    // const navigate = useNavigate ();
    useEffect(() => {
      const fetchApplicantsDetails = async () => {
        // Make a request to the server with the headers
        try {
            const response = await axios.get(`${getBaseApiUrl()}/verify/${token}`);
            setValidationResultSuccess(response.data.message);
            setValidationResultError('');
        }
        catch (error) {
            setValidationResultSuccess('');
            if (error.response && error.response.status === 404) {
                setValidationResultError(error.response.data.message);
               
            } else {
              setValidationResultError("Internal Server Error, Contact Admin");
      
            }

        }
      
      }
      fetchApplicantsDetails();
    }, [token]);

    

    // alert(token)
    return (
        <>
            <HeaderOne />
            


            <section className="contact-page">
        <div className="contact-page-bg" style={{ backgroundImage: 'url(assets/images/backgrounds/contact-page-bg.jpg)' }}></div>
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <div className="contact-page__left">
                <div className="section-title text-left">
                  <span className="section-title__tagline">Email Verification</span>
                </div>
                <div className="contact-page__form">
                    {validationResultError && (<>
                        <h6>{validationResultError}</h6> 
                    </>)
                    }
                    {!validationResultError && validationResultSuccess && (
                        <>
                            <h6>{validationResultSuccess}</h6> 
                            <div> Click  <a href='/login'>here</a> to login </div>
                        </>
                        
                    )}
               
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="contact-page__right">
                <div className="contact-page__img">
                  <img src={"assets/images/resources/contact-page-img-1.jpg"} alt="" />
                  <div className="contact-page__img-shape">
                    <img src={"assets/images/shapes/contact-page-img-shape.png"} alt="" />
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



            <FooterTwo />
        </>
    )
}

export default VerifyEmailComponent;