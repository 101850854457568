import React from 'react';

export default class AboutOne extends React.Component {
    componentDidMount() {

        const $ = window.$;

        if ($(".video-popup").length) {
            $(".video-popup").magnificPopup({
                type: "iframe",
                mainClass: "mfp-fade",
                removalDelay: 160,
                preloader: true,
                fixedContentPos: false,
            });
        }
          
    }
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="about-one about-three">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6">
                                <div className="about-one__left wow slideInLeft" data-wow-delay="100ms" data-wow-duration="2500ms">
                                    <div className="about-one__img-box">
                                        <div className="about-one__img">
                                            <img src={publicUrl+"assets/images/resources/about-one-img-1.jpg"} alt="" />
                                        </div>
                                        <div className="about-one__small-img wow zoomIn animated animated" data-wow-delay="500ms"
                                            data-wow-duration="2500ms">
                                            <img src={publicUrl+"assets/images/resources/about-one-small-img.jpg"} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="about-one__right">
                                    <div className="about-one__right-content">
                                        <div className="section-title text-left">
                                            <span className="section-title__tagline">About Us</span>
                                            <h2 className="section-title__title">
                                            TTWF: Non-profit, Charitable, Apolitical Foundation, Established 2022
                                            </h2>
                                        </div>
                                        <p className="about-one__text">
                                        TTWF, founded on Jan 1, 2022, is a non-profit public welfare foundation supporting charitable, educational, and scientific causes without political engagement. Operating under section 501(c)(3), it ensures assets and income are exclusively used for corporate purposes, fostering an apolitical stance for community development and well-defined eligibility criteria.

                                        </p>
                                        <ul className="list-unstyled about-one__points">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-comment"></span>
                                                </div>
                                                <div className="text">
                                                    <h4>Support low-income individuals from any parts of the world in achieving higher education to develop well rounded, confident, and responsible individuals who aspire to achieve their full potential.</h4>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-comment"></span>
                                                </div>
                                                <div className="text">
                                                    <h4>To uplift the life of the poor, needy, orphan, and disable in achieving the prosperous future.</h4>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="about-one__bottom-video-box">
                                            <div className="about-one__btn-box">
                                                <a href={process.env.PUBLIC_URL + `/about`} className="thm-btn about-one__btn">About More</a>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}