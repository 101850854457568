import React from 'react';
import HeaderOne from '../common/header/HeaderOne';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import MedicalEducationContent from '../components/medical-education/MedicalEducationContent';
import FooterTwo from '../common/footer/FooterTwo';

const MedicalEducation = () => {
    return (
        <>
            <HeaderOne />
            <Breadcrumb 
                heading="Medical Education Program"
                currentPage="Medical Education" 
            />
            <MedicalEducationContent />
            <FooterTwo />
        </>
    )
}

export default MedicalEducation;