import React from 'react';
import HeaderOne from '../common/header/HeaderOne';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import YouthEmpowermentContent from '../components/youth-empowerment/YouthEmpowermentContent';
import FooterTwo from '../common/footer/FooterTwo';

const YouthEmpowerment = () => {
    return (
        <>
            <HeaderOne />
            <Breadcrumb 
                heading="Youth Empowerment Program"
                currentPage="Youth Empowerment" 
            />
            <YouthEmpowermentContent />
            <FooterTwo />
        </>
    )
}

export default YouthEmpowerment;