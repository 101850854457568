import { useState,  useRef } from 'react';
import HeaderOne from '../common/header/HeaderOne';
import axios from 'axios';
import { getBaseApiUrl, validateEmail } from '../utils/utility'
// import {useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import FooterTwo from '../common/footer/FooterTwo';


const ForgotUsername = ({ match }) => {
    const [messageSuccess, setMessageSuccess] = useState('');
    const [messageError, setMessageError] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const recaptchaRef = useRef();
    const [data, setData] = useState({
      email: '',
      captchaValue: null,
    });

    const handleChange = (e) => {
      const { name, value } = e.target;
      setData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };

    const handleCaptchaChange = (value) => {
      setData((prevData) => ({ ...prevData, captchaValue: value }));
    };

    const validateForm = () => {
      // Basic validation, you can add more complex validation as needed
     
      if (!data.email) {
        setMessageError('Email required');
        return false;
      }
      
      if (!validateEmail(data.email)) {
        setMessageError('Invalid Email');
        return false; 
      }

      if (!data.captchaValue) {
        setMessageError('Captcha is missing');
        return false;
      }
      return true;
    };


    const handleFormSubmit = async (e) => {
      e.preventDefault();
      setMessageSuccess('');
      setMessageError('');
      if (!validateForm()) {
        recaptchaRef.current.reset();
        setMessageSuccess('');
        return;
      }
       
      try {
        // Send a request to the server to initiate the password reset process
        const response = await axios.post(`${getBaseApiUrl()}/forgot-username`, data);
        setMessageSuccess(response.data.message);
        setMessageError('');
        setSubmitted(true);
        recaptchaRef.current.reset(); 
      } catch (error) {
        if (error.response && error.response.status === 404) {
            setMessageError(error.response.data.message);
           
             } else {
                    setMessageError("Error getting the username. Please try again.");
    
            }

                
        setMessageSuccess('')
        // setMessageError('Error initiating password reset. Please try again.');
        console.error('Getting the username failed:', error.message);
        recaptchaRef.current.reset(); 
      }
    };


    // const handleEmailChange = (e) => {
    //   setEmail(e.target.value);
    // };
  
    // const handleFormSubmit = async (e) => {
    //   e.preventDefault();
       
    //   try {
    //     // Send a request to the server to initiate the password reset process
    //     const response = await axios.post(`${getBaseApiUrl()}/forgot-username`, { email });
    //     setMessageSuccess(response.data.message);
    //     setMessageError('');
    //   } catch (error) {
    //     if (error.response && error.response.status === 404) {
    //         setMessageError(error.response.data.message);
           
    //          } else {
    //                 setMessageError("Error initiating password reset. Please try again.");
    
    //         }

                
    //     setMessageSuccess('')
    //     // setMessageError('Error initiating password reset. Please try again.');
    //     console.error('Password reset request failed:', error.message);
    //   }
    // };

  return (
    <>
    <HeaderOne />

    <section className="contact-page">
        <div className="contact-page-bg" style={{ backgroundImage: 'url(assets/images/backgrounds/contact-page-bg.jpg)' }}></div>
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <div className="contact-page__left">
                <div className="section-title text-left">
                  <span className="section-title__tagline">Forget Username ?</span>
                </div>
                <div className="contact-page__form">

                {messageError && <div style={{border:'1px solid red', height:'50px', padding: '10px', marginBottom: '15px', marginTop: '-35px', color:'red'}}>
                                        <b>{messageError}</b>
                                    </div>}
                {messageSuccess && <div style={{border:'1px solid green', height:'50px', padding: '10px', marginBottom: '15px', marginTop: '-35px', color:'green'}}>
                    <b>{messageSuccess}</b>
                </div>}


                <form onSubmit={handleFormSubmit}>

                <div className="comment-form__input-box">
                <input type="email" placeholder="Email" value={data.email} name='email' onChange={handleChange} />
                </div>


                <ReCAPTCHA
                          sitekey="6LejiGEpAAAAAHgnoK3Vy6aSzt4EQVbiYmT4G1Ql"
                          onChange={handleCaptchaChange}
                          ref={recaptchaRef}
                        />

                  <div className="comment-form__btn-box" style={{marginTop: 10}}>
                          <button type="submit" className="thm-btn comment-form__btn"  disabled={submitted}>Submit</button>
                        </div>

       
       
            </form>

                        <div style={{paddingTop: 20}}>Forgot Password? Click <a href="/forgot-password">here</a> to reset </div>
                        
                        <div style={{paddingTop: 20}}>Login? Click <a href="/login">here</a> to login </div>
               
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="contact-page__right">
                <div className="contact-page__img">
                  <img src={"assets/images/resources/contact-page-img-1.jpg"} alt="" />
                  <div className="contact-page__img-shape">
                    <img src={"assets/images/shapes/contact-page-img-shape.png"} alt="" />
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>




    <FooterTwo />
    </>
   
  );
};

export default ForgotUsername;
