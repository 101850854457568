import React from 'react';
import HeaderOne from '../common/header/HeaderOne';

// import Breadcrumb from '../common/breadcrumb/Breadcrumb';
// import ContactForm from '../components/contact/ContactForm';
// import ContactInfo from '../components/contact/ContactInfo';
// import ContactMap from '../components/contact/ContactMap';
import RegisterForm from '../components/user/RegisterForm';

import FooterTwo from '../common/footer/FooterTwo';

const Contact = () => {
    return (
        <>
            <HeaderOne />
            <RegisterForm />
            
            <FooterTwo />
        </>
    )
}

export default Contact;