import React from 'react';

export default class ContactInfo extends React.Component {
    render(){
        return (
            <>
                <section className="contact-info">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-4 col-lg-4">
                                {/* Contact Info Single */}
                                <div className="contact-info__single">
                                    <h4 className="contact-info__title">About</h4>
                                    <p className="contact-info__text">
                                    TTWF - a non-profit public welfare foundation dedicated to making a positive impact. 
                                    </p>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4">
                                {/* Contact Info Single */}
                                <div className="contact-info__single">
                                    <h4 className="contact-info__title">Address</h4>
                                    <p className="contact-info__text">
                                        1016 Saffron Dr. Mechanicsburg, PA 17050, United States of <br />
                                        America</p>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4">
                                {/* Contact Info Single */}
                                <div className="contact-info__single contact-info__single-3">
                                    <h4 className="contact-info__title">Contact</h4>
                                    <p className="contact-info__email-phone">
                                        <a href="mailto:ttwfus@gmail.com"
                                            className="contact-info__email">ttwfus@gmail.com</a>
                                        <a href="tel:17178360058" className="contact-info__phone">+1 (717) 836-0058</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}