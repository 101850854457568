import React, {useState, useEffect} from 'react';


export default function CounterTwo() {
    
    const startDate = new Date('2022-11-21T00:00:00'); // Replace with your start date

    const calculateElapsedTime = () => {
        const now = new Date().getTime();
        const elapsedMilliseconds = now - startDate.getTime();

        const days = Math.floor(elapsedMilliseconds / (1000 * 60 * 60 * 24));
        const hours = Math.floor((elapsedMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((elapsedMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((elapsedMilliseconds % (1000 * 60)) / 1000);
        const milliseconds = elapsedMilliseconds % 1000;

        setElapsedTime({ days, hours, minutes, seconds, milliseconds });
    };

    const [elapsedTime, setElapsedTime] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
    });

    const intervalRef = React.useRef(null);

    useEffect(() => {
        intervalRef.current = setInterval(() => {
        calculateElapsedTime();
        }, 100); // Update every 100 milliseconds

        return () => {
        clearInterval(intervalRef.current);
        };
    }, []);
    
    return (
        <>
            <section className="up-comming-events">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="up-comming-events__inner wow fadeInUp" data-wow-delay="100ms">
                                <div className="row">
                                    <div className="col-xl-6">
                                        <div className="up-comming-events__left">
                                            <div className="up-comming-events__content">
                                                <h3 className="up-comming-events__title">TEKNATH TIMSINA WELFARE FOUNDATION</h3>
                                                <ul className="list-unstyled up-comming-events__details">
                                                    
                                                    <li>
                                                        <p> <span className="icon-calendar-1"></span> 
                                                            1016 Saffron Dr. Mechanicsburg, PA 17050.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p> 
                                                            {/* <span className="icon-place"></span> New York, NY */}
                                                            Est. November 21st, 2022
                                                            </p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="up-comming-events__right">
                                            <div className="timer-box">
                                                <div className="countdown-timer">
                                                    <div className="default-coundown">
                                                        <div className="box">
                                                            <div>
                                                                <p>{`${elapsedTime.days} days, ${elapsedTime.hours} hours, ${elapsedTime.minutes} minutes, ${elapsedTime.seconds} seconds`}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
    
}