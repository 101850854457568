import React from 'react';
import HeaderOne from '../common/header/HeaderOne';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import DonorProfileContent from '../components/donation/DonorProfileContent';
import FooterTwo from '../common/footer/FooterTwo';

const DonorProfile = () => {
    return (
        <>
            <HeaderOne />
            <Breadcrumb 
                heading="Donation"
                currentPage="Donor's Profile" 
            />
            <DonorProfileContent />
            <FooterTwo />
        </>
    )
}

export default DonorProfile;