import React from 'react';

export default class YouthEmpowerment extends React.Component {
    componentDidMount() {

        const $ = window.$;

        if ($("#datepicker").length) {
            $("#datepicker").datepicker();
        }
        
        if ($("#datepicker2").length) {
            $("#datepicker2").datepicker();
        }
        
        if ($("#datepicker-inline").length) {
            $("#datepicker-inline").datepicker();
        }
        
        $('input[name="time"]').ptTimeSelect();
          
    }
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                {/* Team Details Start */}
                <section className="team-details">
                    <div className="container">
                        <div className="team-details__inner">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="team-details__top-left">
                                    <div className="team-details__top-content">
                                            <p className="team-details__top-title">Biography Of:-</p>
                                            <h3 className="team-details__top-name">Mr. Teknath Timsina</h3>
                                            {/* <div className="team-details__social">
                                                <a href="#"><i className="fab fa-twitter"></i></a>
                                                <a href="#"><i className="fab fa-facebook"></i></a>
                                                <a href="#"><i className="fab fa-pinterest-p"></i></a>
                                                <a href="#"><i className="fab fa-instagram"></i></a>
                                            </div> */}
                                            
                                            
                                        </div>

                                        <div className="team-details__top-img">
                                        <p className="team-details__top-text-2">
                                        Mr. Teknath Timsina was born on November 21, 1943 in Badare village (sometimes also called
                                        as Salleree) in Chirang district of Bhutan. He was popularly known among the villagers as
                                        “Timsina Purohit”. He is the fourth among eleventh children from two mothers in the Timsina
                                        family. 
                                        Mr. Timsina lives a simple life with kindness, openness, curiosity, and a strong sense of
                                        humanity. He comes from a background where receiving a formal education was unfeasible. Like
                                        many others in the village at that time, he took care of the cattle in the deep forest in order to
                                        assist his parents in their effort to put food on the table to feed a large family. 
                                        His grandfather, Deonarayan Timsina, was a popular Hindhu Purohit (priest providing religious
                                        services) in the area. He had only three living sons left. All of them were uneducated since there
                                        was no formal education system. Mr. Timsina’s father, Tikaram Timsina, was the fourth son
                                        among the born children. Tikaram Timsina was a cattle herder and a farmer from childhood.
                                        Tikaram was sharp and was respected by the villagers.
                                        After the death of his father Deonarayan Timsina, one of the “Jajamaans” (people he provided
                                        religious services for) Shabir Saru Magar came to him and asked if he could provide “Shraddha”
                                        (the annual ritual for remembering the demise one) of his father. When he said that he couldn’t,
                                        Shabir asked him whether, seeing that he couldn’t  offer religious services, he could work as a

                                        pig herder for him.  This made Tikaram Timsina mentally disturbed. He went to a neighbor,
                                        Prajapati Nepal, told him what had happened, and begged to teach him to be a priest. Eventually
                                        he began to provide religious services to the villagers.
                                        Tikaram Timsina had six sons and five daughters from two wives – Tulasa and Madhu Maya.
                                        From Tulasa he had two daughters and four sons – Sabitra, Rukmina, Laxmi, Teknath, Dilliram,
                                        and Narapati respectively. From Madhu Maya, he had two sons and three daughters – Indra
                                        Prashad, Harilal, Ranmaya, Leela, and Laxmi respectively. He married a second wife, which was
                                        not uncommon at the time. 
                                        Mr. Timsina learned the Nepali alphabet and numbers from his father (Tikaram Timsina),
                                        practicing by writing in dry red sand placed on wood because papers and notebooks were not
                                        available. 
                                        He also learned the basics of religious services from his father, Hari Prasad Nepal and mostly
                                        from Narapati Neupane. He began to perform basic religious services such as the “Nwaran”
                                        (naming ceremony for the newborns) from the age of 11. His guru for “Veda” (one of the
                                        prominent religious textbooks) was Premlal Subedi.  
                                        When Mr. Timsina was 27 years old, his father left the village along with his second wife and
                                        children for Sadiya in the state of Assam,  India. Eventually, all the “Jajamaans” that his father
                                        served began to come to him for all religious services. This made him famous in the region and
                                        served more than 200 families from the nine villages. His schedule was tight all year long
                                        because of his commitment to performing ritual activities in the village. Whenever there was no
                                        service scheduled he used to help his wife in the farming.
                                        Mr. Timsina, 17, married Mrs. Deumaya (Khatiwada) Timsina, aged 10. They had five children –
                                        three girls and two boys – Muna, Leela, Chandra, Bhim, and Pashupati respectively. Muna and
                                        Leela never went to school. Chandra left school after attending the fourth grade. Bhim left the
                                        government school and went to the Sanskrit “Paathsala” (Sanskrit school) after attending the
                                        fourth grade. Though Mr. Timsina provided all sorts of non-clinical counseling, he never wanted
                                        to go to school. This made only Pashupati left to continue formal education in the school.
                                        Living in an extended family, along with a stepmother, and step brothers and sisters, was tough;
                                        food was scarce. After half of the family moved to India and all the Jajamaans of his dad used
                                        him for the religious services, Timsina’s living standard gradually improved.  
                                        Before the separation of the family, the first brother of Mr. Timsina- Laxmi got married and lived
                                        by himself in the other village – Dingneygao. This made Mr. Timsina to be the head of the
                                        family for the children of the first wife of Tikaram Timsina. 
                                        Because he hadn’t had the opportunity of a formal education himself, he wanted his younger
                                        brothers, Dilliram and Narapati to further their education, however his sister Rukmina wanted to
                                        adopt and take Dilliram to Manipur, India, where he failed to improved educationally, and
                                        returned to Bhutan  a few years later and married. 
                                        Mr. Timsina sent Narapati to school where he was doing fine. Later, his brother-in-law (the
                                        husband of Sabitra) took him to seek admission in Damphu school, which did not go well.
                                        Without completing a year, he returned home. Soon after his marriage Dilliram moved to Taklai
                                        village in the Sarbhang district along with his cousin brother Manarath Timsina. After a few
                                        years, Narapati married. A year later the couple had  a son. But he too wanted to leave the
                                        birthplace and went to live in nearby Dilliram. This left Mr. Timsina, along with mother Tulasa
                                        and his family, in the birthplace.
                                        Mr. Timsina had a dream of extending his knowledge to the younger generation. From 1981 he
                                        gathered boys from the neighborhood and taught them “Chandi”- which is a worship book of

                                        “Navadurga”- 9 forms of Durga- Goddess of Power. This group included both his sons, Bhim
                                        and Pashupati. 
                                        From this group, Bhim and Narapati went to join Sanskrit Pathsala in 1984, quitting the formal
                                        education, while the remaining boys continued learning “Rudri”- religious text to worship for
                                        Lord Shiva, and also recited during the pleasing of “Graha”-Planetary position of a person that’s
                                        providing inverse effect in the life and activities of the individual. 
                                        From 1985, Mr. Timsina, as it appears, might have had a different opinion on Pashupati. He sent
                                        him  to learn from Chandra Prasad Sharma (Bhandari) and Kapila Mani Sharma. Chandra Prasad
                                        Sharma who was popular in the village, and ended up being an influential doctor in the regions
                                        of Siliguri, Kalimpong, Darjeeling, Sikkim, Bhutan, to Eastern Nepal. He is now popularly
                                        known as Dr. C.P. Sharma who is also the founder administrator of the Nivedita Research Center,
                                        Siliguri, India. While, Kapila Mani also followed his brother and achieved the medical degree.
                                        Serving several years in Thimphu general hospital, he is residing in Phuentsholing and practices
                                        both in Indian and Bhutan.
                                        Since then, whenever Chandra Prasad Sharma or Kapila Mani Sharma were at home for their
                                        vacation, Pashupati stayed by their sides to learn about their medical stories, experiences, and
                                        inspirations; and in their absence he continued his religious education from Mr. Timsina. From
                                        1988 to 1990, Pashupati could not continue the learning since he had to go outside the region due
                                        to a government placement to pursue junior high school education.
                                        By 1991 from the original team who attended classes – Srilal Bhandari, and Gopal Nepal became
                                        a rising “Purohit” with most of the “Karma Kanda” – textbooks used to perform various religious
                                        services, having been completed.
                                        Timsina family became the victim of forcible political eviction in 1992. Mr. Timsina’s family
                                        lived in the Sanischare Refugee Camp in Nepal for close to 17 years before being resettled in
                                        Atlanta, Georgia in 2009. While he was in the refugee camp, he continued practicing the
                                        religious services and was one of busiest ‘Puruhit’ in the camp.
                                        Mr. Timsina was naturalized in 2014. Having lived 10 years in Georgia, he moved to
                                        Mechanicsburg, Pennsylvania in 2019. Currently he is living with his wife Deumaya Timsina
                                        and the four family members of his younger son, Pashupati.
                                        </p>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="team-details__top-right">
                                        <div className="team-details__top-content">
                                            
                                            {/* <div className="team-details__social">
                                                <a href="#"><i className="fab fa-twitter"></i></a>
                                                <a href="#"><i className="fab fa-facebook"></i></a>
                                                <a href="#"><i className="fab fa-pinterest-p"></i></a>
                                                <a href="#"><i className="fab fa-instagram"></i></a>
                                            </div> */}
                                            
                                            <p className="team-details__top-text-2">
                                            <img src={publicUrl+"assets/images/biography/teknath.jpeg"} style={{marginTop:'90px', border: '12px solid orange'}} />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Team Details End */}

            </>
        )
    }
}