import React from 'react';
import {Link} from 'react-router-dom';

export default class PlatinumClubContent extends React.Component {
    componentDidMount() {

        const $ = window.$;

        if ($(".video-popup").length) {
            $(".video-popup").magnificPopup({
                type: "iframe",
                mainClass: "mfp-fade",
                removalDelay: 160,
                preloader: true,
                fixedContentPos: false,
            });
        }
          
    }
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                {/* Project Details Start */}
                <section className="project-details" style={{marginBottom:'40px'}}>
                    <div className="container">
                        
                        <div className="project-details__bottom">
                            <div className="row">
                            <h3 className="project-details__title">Information</h3>
                                <div className="col-xl-12">
                                    <div className="project-details__text-box">
                                        <p className="project-details__text-1">
                                        Any individual, agency, organization, business entity that makes one time sponsorship of $50,000 
                                        in lump sum or signs up with $50,000 face amount value of whole life insurance making TTWF the 
                                        primary beneficiary will receive this membership title. This is a life-time membership title.
                                        

                                        <div style={{marginTop:'20px', marginBlock:'10px'}} >
                                        <h6>Perks of the Platinum Club Membership:</h6>
                                        </div>

                                        <ul>
                                            <li>Will receive on stage chair or the front-line chair during events and activities.</li>
                                            <li>Will be recognized if physically present during events and activities</li>
                                            <li>Will have a biography along with the picture posted on the website.</li>
                                            <li>Will receive priority in the appropriate benefits provided by the foundation to the public and if eligible.</li>
                                            <li>Will be honored with metal inscribed plaque, and green jacket with the foundation patch on it, for the recognition of the membership.    </li>
                                        </ul>
                                        </p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Project Details End */}

                
            </>
        )
    }
}