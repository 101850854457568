import React from 'react';
import HeaderOne from '../common/header/HeaderOne';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import BiographyContent from '../components/biography/BiographyContent';
import FooterTwo from '../common/footer/FooterTwo';

const Biography = () => {
    return (
        <>
            <HeaderOne />
            <Breadcrumb 
                heading="Biography Of "
                currentPage="Teknath Timsina" 
            />
            <BiographyContent />
            <FooterTwo />
        </>
    )
}

export default Biography;