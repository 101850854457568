import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { loadStripe } from '@stripe/stripe-js';
import { getBaseApiUrl } from '../../utils/utility';

function DonateSuccess() {
    useEffect(() => {
        const $ = window.$;

       

        if ($(".progress-levels .progress-box .bar-fill").length) {
            $(".progress-box .bar-fill").each(
              function () {
                $(".progress-box .bar-fill").appear(function () {
                  var progressWidth = $(this).attr("data-percent");
                  $(this).css("width", progressWidth + "%");
                });
              }, {
                accY: 0
              }
            );
        }

        if ($(".count-bar").length) {
            $(".count-bar").appear(
              function () {
                var el = $(this);
                var percent = el.data("percent");
                $(el).css("width", percent).addClass("counted");
              }, {
                accY: -50
              }
            );
        }

        if ($(".count-box").length) {
            $(".count-box").appear(
              function () {
                var $t = $(this),
                  n = $t.find(".count-text").attr("data-stop"),
                  r = parseInt($t.find(".count-text").attr("data-speed"), 10);
        
                if (!$t.hasClass("counted")) {
                  $t.addClass("counted");
                  $({
                    countNum: $t.find(".count-text").text()
                  }).animate({
                    countNum: n
                  }, {
                    duration: r,
                    easing: "linear",
                    step: function () {
                      $t.find(".count-text").text(Math.floor(this.countNum));
                    },
                    complete: function () {
                      $t.find(".count-text").text(this.countNum);
                    }
                  });
                }
              }, {
                accY: 0
              }
            );
        }

        
    
        $('input[name="time"]').ptTimeSelect();
    }, []);

    let publicUrl = process.env.PUBLIC_URL+'/';
    
    const makePayment = async () => {
        
        const apiUrl = getBaseApiUrl('local');
        const stripe = await loadStripe("pk_live_51Oh07fAgi7IHFLotMFXvcAqnfyXdHJ5IXWsBxxsHKqnZbjJMBFV70KbD75FmrYkKZAHP7caAINChqUloKcYgJb8T00puWKvDzW");
        
        const body = {
            product: 'donation'
        };
        const headers = {
            'Content-Type': 'application/json'
        };
        
        const response = await fetch(`${apiUrl}/donation`, {
            method: 'POST',
            headers,
            body: JSON.stringify(body)
        });

        const session = await response.json();
        const result = stripe.redirectToCheckout({
            sessionId: session.id
        });
    }


    return (
        <>
            <section className="donate-now">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-7">
                            <div className="donate-now__left">
                                <div className="donate-now__enter-donation">
                                    <h3 className="donate-now__title">Grateful Donation Acknowledgment!</h3>
                                    <p>
                                    Thank you for our generous donation to the TTWF-us Foundation!

                                    </p>
                                </div>
                                
                                <button type="button" 
                                    className="thm-btn donate-now__payment-info-btn"
                                    onClick={makePayment}
                                    style={{marginTop: 50}}
                                    >
                                        Donate now
                                    </button>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-5">
                            <div className="donate-now__right">
                                <div className="donate-now__causes">
                                    <div className="donate-now__causes-img">
                                        <img src={publicUrl+"assets/images/project/doctor.jpg"} alt="" />
                                    </div>
                                    <div className="donate-now__causes-content-box">
                                        <div className="donate-now__causes-content">
                                            <h3 className="donate-now__causes-title">
                                                <Link to="#">Providing financial support for the MBBS program</Link>
                                            </h3>
                                            <p className="donate-now__causes-text">Aellentesque porttitor lacus quis enim varius
                                                sed efficitur...</p>
                                        </div>
                                        <div className="donate-now__progress">
                                            <div className="bar">
                                                <div className="bar-inner count-bar" data-percent="1.27%">
                                                    <div className="count-text">1.27%</div>
                                                </div>
                                            </div>
                                            <div className="donate-now__goals">
                                                <p><span>$1,270</span> Raised</p>
                                                <p><span>$100,000</span> Goal</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="donation-details__organizer">
                                    <div className="donation-details__organizer-img">
                                        <img src={publicUrl+"assets/images/team/pashupati.jpg"} style={{width:'100px', marginLeft:'-20px'}} alt="" />
                                    </div>
                                    <div className="donation-details__organizer-content">
                                        <p className="donation-details__organizer-date">Created 20 Oct, 2022</p>
                                        <p className="donation-details__organizer-title">Organizer:</p>
                                        <p className="donation-details__organizer-name">Teknath Timsina Welfare Foundation</p>
                                        <ul className="list-unstyled donation-details__organizer-list">
                                            <li>
                                                <div className="icon">
                                                    <span className="fas fa-tag"></span>
                                                </div>
                                                <div className="text">
                                                    <p>Education</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="fas fa-map-marker-alt"></span>
                                                </div>
                                                <div className="text">
                                                    <p>Harrisburg, PA</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default DonateSuccess;
