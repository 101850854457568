import React from 'react';

export default class MedicalEducation extends React.Component {
    componentDidMount() {

        const $ = window.$;

        if ($("#datepicker").length) {
            $("#datepicker").datepicker();
        }
        
        if ($("#datepicker2").length) {
            $("#datepicker2").datepicker();
        }
        
        if ($("#datepicker-inline").length) {
            $("#datepicker-inline").datepicker();
        }
        
        $('input[name="time"]').ptTimeSelect();
          
    }
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                {/* Team Details Start */}
                <section className="team-details">
                    <div className="container">
                        <div className="team-details__inner">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="team-details__top-left">
                                    <div className="team-details__top-content">
                                            <p className="team-details__top-title">Services:-</p>
                                            <h3 className="team-details__top-name">Medical Education Program</h3>
                                            {/* <div className="team-details__social">
                                                <a href="#"><i className="fab fa-twitter"></i></a>
                                                <a href="#"><i className="fab fa-facebook"></i></a>
                                                <a href="#"><i className="fab fa-pinterest-p"></i></a>
                                                <a href="#"><i className="fab fa-instagram"></i></a>
                                            </div> */}
                                            <p className="team-details__top-text-1">
                                                Our Goal:    
                                            </p>
                                            
                                        </div>

                                        <div className="team-details__top-img">
                                        <p className="team-details__top-text-2">
                                                One of the goals of TTWF is to sponsor those who are smart, ambitious, diligent, and yet deprived financially for their MBBS degree including coverage
                                                <img src={publicUrl+"assets/images/project/doctor.jpg"} style={{width:280, float: 'left', marginRight: '20px', marginTop: '10px'}}alt="" />
                                                for tuition fee, books, food, once a year round trip flight fare, annual visa fee, and pocket money until they finish the internship. 
                                                The recipient of this benefit could be based in any part of the world. Once the foundation
                                                generates sufficient funds, the Board of Directors will announce the program, procedure, scope,
                                                and strategies through social media.
                                                The foundation will ask applicants to submit an application with the evidence of the need, scope, and goal of the individual to achieve once completed with the program. The foundation will form a selection committee who will find appropriate candidates to receive the fund. The foundation will pay tuition fees directly to the university, and
                                                it will send the cash for books, stationeries, fooding, travel, visa, and pocket money directly to
                                                the recipient.
                                            </p>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="team-details__top-right">
                                        <div className="team-details__top-content">
                                            
                                            <h3 className="team-details__top-name">Activities</h3>
                                            {/* <div className="team-details__social">
                                                <a href="#"><i className="fab fa-twitter"></i></a>
                                                <a href="#"><i className="fab fa-facebook"></i></a>
                                                <a href="#"><i className="fab fa-pinterest-p"></i></a>
                                                <a href="#"><i className="fab fa-instagram"></i></a>
                                            </div> */}
                                            <p style={{color: 'green'}}>
                                                <b>Now available MBBS scholarship, click <a href="/mbbs-application">here</a> to apply!</b>
                                            </p>
                                            <p className="team-details__top-text-2">
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/bv71h0inzOg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Team Details End */}

            </>
        )
    }
}