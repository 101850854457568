import React from 'react';
import HeaderOne from '../common/header/HeaderOne';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import PlatinumClubContent from '../components/platinum-club/PlatinumClubContent';
import FooterTwo from '../common/footer/FooterTwo';

const PlatinumClub = () => {
    return (
        <>
            <HeaderOne />
            <Breadcrumb 
                heading="Membership"
                currentPage="Platinum Club" 
            />
            <PlatinumClubContent />
            <FooterTwo />
        </>
    )
}

export default PlatinumClub;