import React, { useState, useEffect} from 'react';
import {Link, useNavigate } from "react-router-dom";
import Nav from './Nav';
import MobileMenu from './MobileMenu';
import SearchButton from './SearchButton';
import { useCookies } from 'react-cookie';

const HeaderOne = () => {
    const [cookies] = useCookies(['token']);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate ();
    const [, , removeCookie] = useCookies(['token']);

    useEffect(() => {
        // Check if the 'token' cookie exists
        const token = cookies['token'];
        setIsLoggedIn(!!token); // Set isLoggedIn based on the presence of the token
      }, [cookies]);
    

    const handleLogout = () => {
        // Remove the 'token' cookie
        removeCookie('token', { path: '/' });
        navigate('/');
      };
    
      
      const blinkingTextStyle = {
        animation: 'blink 2s infinite', // Adjust duration and other parameters as needed
        color: 'orange'
      };
    const test = () =>{
        navigate('/mbbs-application/');
    }
    let publicUrl = process.env.PUBLIC_URL+'/'
    return (
        <>
            <header className="main-header clearfix">
                <div className="main-header__top clearfix">
                    <div className="container clearfix">
                        <div className="main-header__top-inner clearfix">
                            <div className="main-header__top-left">
                                <ul className="list-unstyled main-header__top-address">
                                    <li>
                                        <div className="icon">
                                            <span className="icon-call"></span>
                                        </div>
                                        <div className="text">
                                            <p><a href="tel:+1 (717) 836 0058">+1 (717) 836 0058</a></p>
                                        </div>
                                    </li>
                                    <li>
                                        <div style={{display:'flex', flexDirection:'row'}}>
                                            <div className="icon">
                                                <span className="icon-envelope" ></span>
                                            </div>
                                            <div className="text" >
                                                <p><a href="mailto:ttwfus@gmail.com">ttwfus@gmail.com</a></p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span className="icon-place"></span>
                                        </div>
                                        <div className="text" >
                                            <p style={{marginLeft: -5}}>1016 Saffron Dr, Mechanicsburg</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="main-header__top-right">


                                <ul className="list-unstyled main-header__top-right-content">
                                    <li>
                                    <div style={blinkingTextStyle }> <span onClick={test}>MBBS Scholarship available! Deadline April 1st!</span>
                                    <style>
                                    {`
                                                @keyframes blink {
                                                    0%, 100% {
                                                    opacity: 1;
                                                    }
                                                    50% {
                                                    opacity: 0;
                                                    }
                                                }

                                                .blinking-text {
                                                    display: inline-block;
                                                }
                                                `}
                                    </style>
                                    </div>

                                    </li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                               

                                    <li>
                                    {isLoggedIn ? (
                                        <div onClick={handleLogout}>
                                            <Link to={process.env.PUBLIC_URL + `/`}>Logout</Link>
                                        </div>
                                        
                                    ):(
                                        
                                        <Link to={process.env.PUBLIC_URL + `/login`}>Login</Link>
                                    )}
                                        
                                    
                                    </li>
                                  
                                    <SearchButton />
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <nav className="main-menu clearfix">
                    <div className="container clearfix">
                        <div className="main-menu-wrapper clearfix">
                            <div className="main-menu-wrapper__left" >
                                <div className="main-menu-wrapper__logo" >
                                    <Link to={process.env.PUBLIC_URL + `/`}>
                                        <img src={publicUrl+"assets/images/resources/logo-ttwf-13.png"} alt="" />
                                        {/* <span style={{fontSize: 25, color: "black"}}><b>TTWF US</b></span> */}
                                    </Link>
                                    
                                </div>

                               


                            </div>
                            <div className="main-menu-wrapper__right">
                                <div className="main-menu-wrapper__main-menu">
                                    <MobileMenu />
                                    <Nav />
                                </div>
                                <Link to={process.env.PUBLIC_URL + `/donate-now`} className="thm-btn main-header__btn">Donate Now</Link>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    )

}
// export default class HeaderOne extends React.Component {
//     render(){
//         let publicUrl = process.env.PUBLIC_URL+'/'
//         return (
//             <>
//                 <header className="main-header clearfix">
//                     <div className="main-header__top clearfix">
//                         <div className="container clearfix">
//                             <div className="main-header__top-inner clearfix">
//                                 <div className="main-header__top-left">
//                                     <ul className="list-unstyled main-header__top-address">
//                                         <li>
//                                             <div className="icon">
//                                                 <span className="icon-call"></span>
//                                             </div>
//                                             <div className="text">
//                                                 <p><a href="tel:+1 (717) 836 0058">+1 (717) 836 0058</a></p>
//                                             </div>
//                                         </li>
//                                         <li>
//                                             <div className="icon" >
//                                                 <span className="icon-envelope"></span>
//                                             </div>
//                                             <div className="text">
//                                                 <p><a href="mailto:ttwfus@gmail.com">ttwfus@gmail.com</a></p>
//                                             </div>
//                                         </li>
//                                         <li>
//                                             <div className="icon">
//                                                 <span className="icon-place"></span>
//                                             </div>
//                                             <div className="text" >
//                                                 <p style={{marginLeft: -5}}>1016 Saffron Dr, Mechanicsburg</p>
//                                             </div>
//                                         </li>
//                                     </ul>
//                                 </div>
//                                 <div className="main-header__top-right">
//                                     <ul className="list-unstyled main-header__top-right-content">
//                                         <li><Link to={process.env.PUBLIC_URL + `/login`}>Login</Link></li>
                                      
//                                         <SearchButton />
//                                     </ul>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <nav className="main-menu clearfix">
//                         <div className="container clearfix">
//                             <div className="main-menu-wrapper clearfix">
//                                 <div className="main-menu-wrapper__left" >
//                                     <div className="main-menu-wrapper__logo" >
//                                         <Link to={process.env.PUBLIC_URL + `/`}>
//                                             <img src={publicUrl+"assets/images/resources/logo-ttwf-13.png"} alt="" />
//                                             {/* <span style={{fontSize: 25, color: "black"}}><b>TTWF US</b></span> */}
//                                         </Link>
//                                     </div>
//                                 </div>
//                                 <div className="main-menu-wrapper__right">
//                                     <div className="main-menu-wrapper__main-menu">
//                                         <MobileMenu />
//                                         <Nav />
//                                     </div>
//                                     <Link to={process.env.PUBLIC_URL + `/donate-now`} className="thm-btn main-header__btn">Donate Now</Link>
//                                 </div>
//                             </div>
//                         </div>
//                     </nav>
//                 </header>
//             </>
//         )
//     }
// }

export default HeaderOne;