import React from 'react';
import {Link} from 'react-router-dom';

export default class TeamOne extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="team-one">
                    <div className="container">
                        <div className="section-title text-center">
                            <span className="section-title__tagline">Operating Team</span>
                            <h2 className="section-title__title">Meet Our Current Operating Team.</h2>
                        </div>

                        <div className="row justify-content-center">
                        <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="200ms">
                            {/* Team One Single */}
                            <div className="team-one__single">
                            <div className="team-one__img">
                                <img src={publicUrl + "assets/images/team/pashupati.jpg"} alt="" />
                            </div>
                            <div className="team-one__content">
                                <h4 className="team-one__name">
                                <Link to={process.env.PUBLIC_URL + `/team-details`}>Pashupati Timsina</Link>
                                </h4>
                                <p className="team-one__title">Chairman</p>
                                <div className="team-one__social">
                                <a href="#"><i className="fab fa-twitter"></i></a>
                                <a href="https://www.facebook.com/atlantic.finances" target='_blank'><i className="fab fa-facebook"></i></a>
                                <a href="#"><i className="fab fa-pinterest-p"></i></a>
                                <a href="#"><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>




                        <div className="row">
                            <div className="col-xl-4 col-lg-4 wow fadeInLeft" data-wow-delay="100ms">
                                {/* Team One Single */}
                                <div className="team-one__single">
                                    <div className="team-one__img">
                                        <img src={publicUrl+"assets/images/team/sovit.jpg"} height="280" alt="" />
                                    </div>
                                    <div className="team-one__content">
                                        <h4 className="team-one__name"><Link to={process.env.PUBLIC_URL + `/team-details`}>Sovit Adhikari</Link></h4>
                                        <p className="team-one__title">Secretary</p>
                                        <div className="team-one__social">
                                            <a href="#"><i className="fab fa-twitter"></i></a>
                                            <a href="#"><i className="fab fa-facebook"></i></a>
                                            <a href="#"><i className="fab fa-pinterest-p"></i></a>
                                            <a href="#"><i className="fab fa-instagram"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="200ms">
                                {/* Team One Single */}
                                <div className="team-one__single">
                                    <div className="team-one__img">
                                        <img src={publicUrl+"assets/images/team/deepika.jpg"} height="280" alt="" />
                                    </div>
                                    <div className="team-one__content">
                                        <h4 className="team-one__name"><Link to={process.env.PUBLIC_URL + `/team-details`}>Deepika timsina</Link></h4>
                                        <p className="team-one__title">Treasurer</p>
                                        <div className="team-one__social">
                                            <a href="#"><i className="fab fa-twitter"></i></a>
                                            <a href="https://www.facebook.com/deepika.timsina.58"><i className="fab fa-facebook"></i></a>
                                            <a href="#"><i className="fab fa-pinterest-p"></i></a>
                                            <a href="#"><i className="fab fa-instagram"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 wow fadeInRight" data-wow-delay="100ms">
                                {/* Team One Single */}
                                <div className="team-one__single">
                                    <div className="team-one__img">
                                        <img src={publicUrl+"assets/images/team/yagya.jpeg"} height="280" alt="" />
                                    </div>
                                    <div className="team-one__content">
                                        <h4 className="team-one__name"><Link to={process.env.PUBLIC_URL + `/team-details`}>Yagya Adhikari</Link></h4>
                                        <p className="team-one__title">Board of Directors</p>
                                        <div className="team-one__social">
                                            <a href="#"><i className="fab fa-twitter"></i></a>
                                            <a href="#"><i className="fab fa-facebook"></i></a>
                                            <a href="#"><i className="fab fa-pinterest-p"></i></a>
                                            <a href="#"><i className="fab fa-instagram"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-xl-4 col-lg-4 wow fadeInLeft" data-wow-delay="100ms">
                                {/* Team One Single */}
                                <div className="team-one__single">
                                    <div className="team-one__img">
                                        <img src={publicUrl+"assets/images/team/nabin.jpeg"} alt="" />
                                    </div>
                                    <div className="team-one__content">
                                        <h4 className="team-one__name"><Link to={process.env.PUBLIC_URL + `/team-details`}>Nabin Bhattarai</Link></h4>
                                        <p className="team-one__title">Board of Directors</p>
                                        <div className="team-one__social">
                                            <a href="#"><i className="fab fa-twitter"></i></a>
                                            <a href="#"><i className="fab fa-facebook"></i></a>
                                            <a href="#"><i className="fab fa-pinterest-p"></i></a>
                                            <a href="#"><i className="fab fa-instagram"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </section>
            </>
        )
    }
}