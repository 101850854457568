import React from 'react';
import {Link} from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Pagination, Navigation } from "swiper";

export default class HeroOne extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="main-slider">
                    <Swiper 
                        loop={true}
                        slidesPerView={1}
                        effect="fade"
                        autoplay={{delay:   5000}}
                        modules={[EffectFade, Pagination, Navigation]}
                        pagination={{
                            el: "#main-slider-pagination",
                            type: "bullets",
                            clickable: true
                        }}
                        navigation={{
                            nextEl: "#main-slider__swiper-button-next",
                            prevEl: "#main-slider__swiper-button-prev"
                        }}
                        className="swiper-container thm-swiper__slider">

                        <div className="swiper-wrapper">

                            {/* Start Swiper Slide Single */}
                            <SwiperSlide className="swiper-slide">
                                <div className="image-layer" style={{backgroundImage: 'url('+publicUrl+'assets/images/backgrounds/dr-rhea.jpg)'}}></div>
                                <div className="main-slider-shape-1"></div>
                                <div className="main-slider-shape-2"></div>
                                <div className="main-slider-shape-3 zoominout"></div>
                                <div className="main-slider-shape-4 zoominout-2"></div>
                                <div className="main-slider-shape-5">
                                    <img src={publicUrl+"assets/images/shapes/main-slider-shape-5.png"} alt="" />
                                </div>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xl-7">
                                            <div className="main-slider__content">
                                                <h2>Give Hope <span>Transform</span> Lives, <br /> Donate Now.</h2>
                                                <p>Empower those in need with hope, transforming lives <br /> through your
                                                generosity—make a difference by donating now... </p>
                                                <Link to={process.env.PUBLIC_URL + `/about`} className="thm-btn main-slider__btn">Leran More</Link>
                                                <div className="main-slider-arrow">
                                                    <img src={publicUrl+"assets/images/shapes/main-slider-shape-1.png"} className="float-bob-x" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            {/* End Swiper Slide Single */}

                            {/* Start Swiper Slide Single */}
                            <SwiperSlide className="swiper-slide">
                                <div className="image-layer" style={{backgroundImage: 'url('+publicUrl+'assets/images/backgrounds/bidhya-nepal.jpeg)'}}></div>
                                <div className="main-slider-shape-1"></div>
                                <div className="main-slider-shape-2"></div>
                                <div className="main-slider-shape-3 zoominout"></div>
                                <div className="main-slider-shape-4 zoominout-2"></div>
                                <div className="main-slider-shape-5">
                                    <img src={publicUrl+"assets/images/shapes/main-slider-shape-5.png"} alt="" />
                                </div>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xl-7">
                                            <div className="main-slider__content">
                                                <h2>TTWF <span>Empowers</span> Youth <br /> Worldwide:Entrepreneurial Skills</h2>
                                                <p>Teknath Timsina Welfare Foundation empowers global youth by <br /> fostering
                                                entrepreneurial skills, unlocking potentials for sustainable growth... </p>
                                                <Link to={process.env.PUBLIC_URL + `/about`} className="thm-btn main-slider__btn">Leran More</Link>
                                                <div className="main-slider-arrow">
                                                    <img src={publicUrl+"assets/images/shapes/main-slider-shape-1.png"} className="float-bob-x" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            {/* End Swiper Slide Single */}

                            {/* Start Swiper Slide Single */}
                            <SwiperSlide className="swiper-slide">
                                <div className="image-layer" style={{backgroundImage: 'url('+publicUrl+'assets/images/backgrounds/banner-2.jpeg)'}}></div>
                                <div className="main-slider-shape-1"></div>
                                <div className="main-slider-shape-2"></div>
                                <div className="main-slider-shape-3 zoominout"></div>
                                <div className="main-slider-shape-4 zoominout-2"></div>
                                <div className="main-slider-shape-5">
                                    <img src={publicUrl+"assets/images/shapes/main-slider-shape-5.png"} alt="" />
                                </div>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xl-7">
                                            <div className="main-slider__content">
                                                <h2>TTWF: <span>Empowering</span> Dreams, <br /> Sanskrit Education for All Youth.</h2>
                                                <p>Teknath Timsina Welfare Foundation empowers dreams, ensuring <br /> Sanskrit education
                                                access for all youth, fostering inclusivity and equality... </p>
                                                <Link to={process.env.PUBLIC_URL + `/about`} className="thm-btn main-slider__btn">Leran More</Link>
                                                <div className="main-slider-arrow">
                                                    <img src={publicUrl+"assets/images/shapes/main-slider-shape-1.png"} className="float-bob-x" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            {/* End Swiper Slide Single */}

                        </div>

                        <div className="swiper-pagination" id="main-slider-pagination"></div>

                        <div className="main-slider__nav">
                            <div className="swiper-button-prev" id="main-slider__swiper-button-next">
                                <i className="fa fa-long-arrow-alt-left"></i>
                            </div>
                            <div className="swiper-button-next" id="main-slider__swiper-button-prev">
                                <i className="fa fa-long-arrow-alt-right"></i>
                            </div>
                        </div>

                    </Swiper>
                </section>
            </>
        )
    }
}