import { useState, useEffect, useRef  } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import {useNavigate } from "react-router-dom";
import { FaFilePdf  } from "react-icons/fa";
import { Dropdown, Button, Form  } from 'react-bootstrap';
import { getBaseApiUrl } from '../../utils/utility'



const MBBSApplicationContent = ({me, isLoggedIn}) => {

  const [applicants, setApplicants] = useState(null);
  const [cookies, setCookie] = useCookies(['token']);
  const [errorMessage, setErrorMessage] = useCookies('');
 
  
    useEffect(() => {
        // Check if the 'token' cookie exists
        if (isLoggedIn) {
          fetchApplicantsDetails();
        }
      }, [cookies, isLoggedIn]);
  

      const fetchApplicantsDetails = async () => {
        const headers = {
          'Authorization': `Bearer ${cookies['token']}`,
        };
        
        // Make a request to the server with the headers
        axios.get(`${getBaseApiUrl()}/admin/applicants`, { headers })
          .then(response => {
            // console.log(response.data);
            setApplicants(response.data)
          })
          .catch(error => {
            console.log(error);
          });

      }
    

      const handleDownload = async (filePath, fileName) => {
        
        try {
          const response = await fetch(`${getBaseApiUrl()}/api/download?filePath=${encodeURIComponent(filePath)}`);
          const blob = await response.blob();
    
          // Create a temporary link element
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
    
          // Trigger a click on the link to start the download
          link.click();
    
          // Remove the link
          document.body.removeChild(link);
        } catch (error) {
          console.error('Error downloading file:', error);
        }
      };
      const tableStyle = {
        borderCollapse: 'collapse',
        width: '100%',
      };
    
      const thStyle = {
        border: '1px solid #dddddd',
        textAlign: 'left',
        padding: '8px',
        backgroundColor: '#f2f2f2'
      };
      const tdStyle = {
        border: '1px solid #dddddd',
        textAlign: 'left',
        padding: '8px',
      }
   

      const handleSaveStatus = async (newStatus, reason, applicant) => {
        // Implement logic to save the new status (e.g., send API request)
        
        try {
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookies['token']}`
          }
          const data = {status: newStatus, reason: reason};

          const response = await axios.put(`${getBaseApiUrl()}/admin/applicant/${applicant._id}`, data, {
            headers: headers,
          });
    
          // console.log(response.data);
          console.log('update success');
          // useNavigate('/mbbs-application')
        } catch (error) {
            setErrorMessage('update failed'); 
            console.error('update failed:', error.message);
          // Handle login failure, show error message, etc.
        }



        // console.log(applicant)
        // console.log(`Saving status: ${newStatus}, reason: ${reason}`);
      };



      const InlineEditStatus = ({ initialStatus, onSave, applicant }) => {
        const [editing, setEditing] = useState(false);
        const [status, setStatus] = useState(initialStatus);
        const [reason, setReason] = useState('');
        const inputRef = useRef(null);

    
        const handleEditClick = () => {
          setEditing(true);
        };
    
        const handleSaveClick = () => {
          setEditing(false);
          onSave(status, reason, applicant);
        };
    
        const handleCancelClick = () => {
          setEditing(false);
          setStatus(initialStatus);
        };
    
        const handleStatusChange = (newStatus) => {
          setStatus(newStatus);
        };
        
        const handleReasonChange = (event) => {
          setReason(event.target.value);
        };

        return (
          <div>
            {editing ? (
              <>
                <Dropdown onSelect={handleStatusChange}>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {status}
                  </Dropdown.Toggle>
    
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="not-applied">Not Applied</Dropdown.Item>
                    <Dropdown.Item eventKey="submitted">Submitted</Dropdown.Item>
                    <Dropdown.Item eventKey="incomplete">Incomplete</Dropdown.Item>
                    <Dropdown.Item eventKey="under-review">Under-review</Dropdown.Item>
                    <Dropdown.Item eventKey="interviewing">Interviewing</Dropdown.Item>
                    <Dropdown.Item eventKey="short-listed">Short-listed</Dropdown.Item>
                    <Dropdown.Item eventKey="selected">Selected</Dropdown.Item>
                    <Dropdown.Item eventKey="rejected">Rejected</Dropdown.Item>

                    {/* Add more status options as needed */}
                  </Dropdown.Menu>
                </Dropdown>
                <Form.Control
              type="text"
              placeholder="Reason for update..."
              value={reason}
              onChange={handleReasonChange}
              ref={inputRef}
            />
                <Button variant="primary" size="sm" onClick={handleSaveClick}>
                  Save
                </Button>
                <Button variant="secondary" size="sm" onClick={handleCancelClick}>
                  Cancel
                </Button>
              </>
            ) : (
              <div onClick={handleEditClick}>{status}</div>
            )}
          </div>
        );
      };












      


  
  return (
    <>
      <section className="contact-page" >
        <div className="contact-page-bg" style={{ backgroundImage: 'url(/assets/images/backgrounds/contact-page-bg.jpg)' }}></div>
        <div className="container">
          <div className='row section-title' style={{marginTop: -80, color: "red"}}>
            <div className='col' style={{textAlign: "left"}} >
              {me && me.firstName && <span><b>Welcome, {me.firstName}!</b></span>}
              </div>
          </div>
          <div className='row '>
            <div className='section-title__tagline' style={{textAlign: "left", }} >
                 admin view
              </div>
              <div className='line'>
                   <hr />
              </div>
          </div>
           <div className='row'>
            <div className='col'>
              <table style={tableStyle}>
                <tr >
                  <th style={thStyle}>Applicant Name</th>
                  <th style={thStyle}>Program Name</th>
                  <th style={thStyle}>Applied Date</th>
                  <th style={thStyle}>Application Status</th>
                  <th style={thStyle}>Contract</th>
                  <th style={thStyle}>Consent</th>
                  <th style={thStyle}>Acknowledgement</th>                
                  <th style={thStyle}>Application</th>
                  <th style={thStyle}>Action</th>
                </tr> 

                {applicants && applicants.map(applicant => {
                 
                    const dateString = applicant.program[0].appliedDate;
                    let formattedDate = "N/A"
                    if (dateString) {
                      const dateObject = new Date(dateString);
                      formattedDate = dateObject.toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      });
                    }
                    return(
                  
                      <tr key={applicant.username}>
                        {/* Assuming `item` contains the applicant information */}
                        <td style={tdStyle}>{`${applicant.firstName} ${applicant.middleName ? applicant.middleName + ' ' : ''}${applicant.lastName}`}</td>
                        <td style={tdStyle}>{applicant.program[0].programName }</td>
                        <td style={tdStyle}>{formattedDate}</td>
                        <td style={tdStyle}>
                          
                            <InlineEditStatus
                            initialStatus={applicant.program[0].applicationStatus[applicant.program[0].applicationStatus.length - 1].status}
                            onSave={handleSaveStatus}
                            applicant={applicant}
                          />

                        
                        </td>
                        <td style={tdStyle}>{
                          applicant.program[0].files.map((file) => {
                            if (file.documentType === "consent") {
                              const fileDownloadAs = `${applicant.firstName}_consent.pdf`;
                              return (<button key={file.filePath} onClick={()=>handleDownload (file.filePath, fileDownloadAs)} className="btn btn-outline-primary"> <FaFilePdf /> </button>);
                            
                            }
                            
                          })  }
                        </td>

                        <td style={tdStyle}>{
                          applicant.program[0].files.map((file) => {
                            if (file.documentType === 'contract') {
                              const fileDownloadAs = `${applicant.firstName}_contract.pdf`;
                              return (<button key={file.filePath} onClick={()=>handleDownload (file.filePath, fileDownloadAs)} className="btn btn-outline-primary"> <FaFilePdf /> </button>);
                            
                            }
                            
                          })  }
                        </td>
                        

                        <td style={tdStyle}>{
                          applicant.program[0].files.map((file) => {
                            if (file.documentType === 'acknowledgement') {
                              const fileDownloadAs = `${applicant.firstName}_acknowledgement.pdf`;
                              return (<button key={file.filePath} onClick={()=>handleDownload (file.filePath, fileDownloadAs)} className="btn btn-outline-primary"> <FaFilePdf /> </button>);

                            }
                            
                          })  }
                        </td>
                        <td style={tdStyle}>{
                          applicant.program[0].files.map((file) => {
                            if (file.documentType === 'application') {
                              const fileDownloadAs = `${applicant.firstName}_application.pdf`;
                              return (<button key={file.filePath} onClick={()=>handleDownload (file.filePath, fileDownloadAs)} className="btn btn-outline-primary"> <FaFilePdf /> </button>);
                            }
                            
                          })  }
                        </td>
                        <td style={tdStyle}>Edit</td>
                      
                       
                      </tr>
                    )
                    
                    })}

                








                
              </table>
            </div>
           </div>
          
        </div>
      </section>
    </>
  );
};

export default MBBSApplicationContent;
