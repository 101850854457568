import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import {useNavigate } from "react-router-dom";
import { FaFilePdf  } from "react-icons/fa";
import { Card, Button } from 'react-bootstrap';
import { getBaseApiUrl } from '../../utils/utility'


const ShowHideComponents = ({me}) => {
    
  // console.log(me);
  const [showComponents, setShowComponents] = useState(false);
  const [currentComponentIndex, setCurrentComponentIndex] = useState(0);
  const [applicationDone, setApplicationDone] = useState(false);
  // const [applicationData, setApplicationData] = useState(false);
  const [formSubmissionMessage, setFormSubmissionMessage] = useState(null);
  const components = [
    { title: 'TTWF Parent Consent', content: 'Please upload the signed copy of TTWF Parent Consent form below', documentType: 'consent' },
    { title: 'TTWF MBBS Scholarship Contract', content: 'Please upload the signed copy of TTWF MBBS Scholarship Contract Agreement.', documentType: 'contract' },
    { title: 'TTWF Policy Package Acknowledgement', content: 'Please upload the signed copy of TTWF Acknowledgement of receipt of the overview of the policy package', documentType: 'acknowledgement' },
    { title: 'TTWF MBBS Application', content: 'Please upload the signed copy of application. Please merge application along with all required documents like essay, etc into one pdf file and then upload', documentType: 'application' },
  ];

  


  const beginApplication = () => {
    setShowComponents(true);
  };

  const nextComponent = () => {
    setFormSubmissionMessage(null);
    if (currentComponentIndex === components.length - 1) {
      setApplicationDone(true);
      // setApplicationData()
    } else {
      setCurrentComponentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const previousComponent = () => {
    setCurrentComponentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const startOver = () => {
    setCurrentComponentIndex(0);
    setApplicationDone(false);
    setFormSubmissionMessage(null);
  };

  const [cookies, setCookie] = useCookies(['token']);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    useEffect(() => {
        // Check if the 'token' cookie exists
        const token = cookies['token'];
        setIsLoggedIn(!!token); // Set isLoggedIn based on the presence of the token
      }, [cookies]);
    const [file, setFile] = useState(null);
    const [document, setDocument] = useState('');

    
    const handleFileChange = (documentType, event) => {
        setFile(event.target.files[0]);
        setDocument(documentType);
    };

 
  // const handleUpload = (documentType) => {
  //   // Implement the logic to handle document upload based on the document type
  //   console.log(`Uploading ${documentType}...`);
  // };
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleFormSubmit = async (documentType, event) => {
    event.preventDefault();
    // alert(documentType)

    const program = 'mbbs'
    if (!file) {
      alert('Please select a file');
      return;
    }
    // console.log(document);
    const formData = new FormData();
    formData.append('file', file);
    // formData.append('documentType', document);

    try {
        const headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${cookies['token']}`
          }
        const response = await axios.post(`${getBaseApiUrl()}/upload/${program}/${document}`, formData, {
        headers: headers,
      });

      // console.log(response.data);
      // alert('File uploaded successfully');
      setFile(null); // Reset the file state after successful upload
      setFormSubmissionMessage(capitalizeFirstLetter(document)+' document uploaded successfully!!!'); // Set a success message
      // nextComponent();
    } catch (error) {
      console.error('File upload failed:', error.message);
      alert('File upload failed');
    }
  };


// const cardBgColor = '#0a262c';
const cardBgColor = '#001d23';
const orangeColor = '#ff6f0f';  // this is ornage color
const deepOrangeColor = '#CC5500';  // this is ornage color
const headerBgColor = '#0a262c';  // this is footer-color color
const footerBgColor = '#0a262c';    // this is footer-color color
const textColor = '#E2DFD2';

const cardStyle = {
    position: "absolute",
    bottom: "0",
    left: "0",
    width: "100%",
    backgroundColor: "#f0f0f0",
    padding: "10px",
  };

  const hiddenTextStyle = {
    display: 'none', // Hide the text by default
  };

  

  return (
    <div>

          {applicationDone && 

            <Card style={{backgroundColor: cardBgColor, width: '38rem', height: '23rem', color: textColor}}>
                    <Card.Header style={{backgroundColor: headerBgColor}}>Application Completed!!!</Card.Header>
                    <Card.Body>
                          Congratulation!!! Application is completed! 
                    </Card.Body>
                    <Card.Body>
                    We will review and contact you if more info needed!
                    </Card.Body>
                    <Card.Body>
                    </Card.Body>
                    
                 </Card>

          }

      {!applicationDone && !showComponents && (
                 <Card style={{backgroundColor: cardBgColor, width: '38rem', height: '23rem', color: textColor}}>
                    <Card.Header style={{backgroundColor: headerBgColor}}>Ready to Apply?</Card.Header>
                    <Card.Body>
                        This widget will walk you through the documents to be uploaded for the  Application
                    </Card.Body>
                    <Card.Body>
                        Complete the four documents listed in downloads to begin the application. 
                    </Card.Body>
                    <Card.Body>
                    <Button type="submit" onClick={beginApplication} style={{backgroundColor: deepOrangeColor,  color: '#E2DFD2', width: '15rem', height: '4rem'}}>Begin Application</Button>
                    </Card.Body>
                    <Card.Footer style={{backgroundColor: footerBgColor}}>Click begin application to start application</Card.Footer>
                 </Card>
      )}

      {!applicationDone && showComponents && (
        <div>
          {components.map((component, index) => (

<div>

        {formSubmissionMessage ? (
            <Card key={index} style={{backgroundColor: cardBgColor, width: '38rem', color: textColor, display: index === currentComponentIndex ? 'block' : 'none' }}>
                <Card.Header style={{backgroundColor: headerBgColor}}>{component.title}</Card.Header>
                <Card.Body>
                <Card.Title><br/></Card.Title>
                <Card.Body> {formSubmissionMessage}</Card.Body>

              
                <Card.Body>
                  Please click <Button type="button" onClick={nextComponent} style={{ backgroundColor: deepOrangeColor, color: '#E2DFD2' }}>NEXT</Button> to upload Next Document
                </Card.Body>

                <Card.Text>
                
               
                </Card.Text>
                
                </Card.Body>
                <Card.Body> {applicationDone ? ( <span>Congratulation!!! Application is done!</span>):(<div style={hiddenTextStyle} ><span>Application is done!</span></div>)}</Card.Body>
                
                <Card.Footer style={{backgroundColor: footerBgColor}}>
                    <Button type="submit" onClick={startOver} style={{backgroundColor: deepOrangeColor,  color: '#E2DFD2'}}>Start Over</Button>
                    
                    <div style={{float: "right"}}>
                   
 
                </div>
             
                    </Card.Footer>
                </Card>
        ) : (


          <Card key={index} style={{backgroundColor: cardBgColor, width: '38rem', color: textColor, display: index === currentComponentIndex ? 'block' : 'none' }}>
                <Card.Header style={{backgroundColor: headerBgColor}}>{component.title}</Card.Header>
                <Card.Body>
                <Card.Title><br/></Card.Title>
                <Card.Subtitle className="mb-5 text-muted">{component.content}</Card.Subtitle>
                <Card.Text>
                    
                    <form  onSubmit={(event) => handleFormSubmit(component.documentType, event)}>

                        <label>
                       
                        <input type="file" name="file" onChange={(event) => handleFileChange(component.documentType, event)} />
                        </label>
                        <Button type="submit" style={{backgroundColor: deepOrangeColor,  color: '#E2DFD2'}}>Submit</Button>
                       

                        </form>
                       
                </Card.Text>
                
                </Card.Body>
                <Card.Body> {applicationDone ? ( <span>Congratulation!!! Application is done!</span>):(<div style={hiddenTextStyle} ><span>Application is done!</span></div>)}</Card.Body>
                
                <Card.Footer style={{backgroundColor: footerBgColor}}>
                    <Button type="submit" onClick={startOver} style={{backgroundColor: deepOrangeColor,  color: '#E2DFD2'}}>Start Over</Button>
                    
                    <div style={{float: "right"}}>
                  

                </div>
             
                    </Card.Footer>
                </Card>
          
        )
}

  
               
                </div>

          ))}
          
        </div>
      )}
    </div>
  );
};

export default ShowHideComponents;
