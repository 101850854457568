import React from 'react';

export default class SanskritEducation extends React.Component {
    componentDidMount() {

        const $ = window.$;

        if ($("#datepicker").length) {
            $("#datepicker").datepicker();
        }
        
        if ($("#datepicker2").length) {
            $("#datepicker2").datepicker();
        }
        
        if ($("#datepicker-inline").length) {
            $("#datepicker-inline").datepicker();
        }
        
        $('input[name="time"]').ptTimeSelect();
          
    }
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                {/* Team Details Start */}
                <section className="team-details">
                    <div className="container">
                        <div className="team-details__inner">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="team-details__top-left">
                                    <div className="team-details__top-content">
                                            <p className="team-details__top-title">Services:-</p>
                                            <h3 className="team-details__top-name">Sanskrit Education Program</h3>
                                            {/* <div className="team-details__social">
                                                <a href="#"><i className="fab fa-twitter"></i></a>
                                                <a href="#"><i className="fab fa-facebook"></i></a>
                                                <a href="#"><i className="fab fa-pinterest-p"></i></a>
                                                <a href="#"><i className="fab fa-instagram"></i></a>
                                            </div> */}
                                            <p className="team-details__top-text-1">
                                                Our Goal:    
                                            </p>
                                            
                                        </div>

                                        <div className="team-details__top-img">
                                        <p className="team-details__top-text-2">
                                        This was the first program launched by Mr. Timsina in his life which this foundation will continue. 
                                        Tekanth Timsina Welfare Foundation(TTWF) will sponsor the youth who want to pursue sanskrit education 
                                        despite the caste, creed, color, origination, or gender. Presumably, the destination for this program
                                        will be in India or Nepal – any convenient locations that candidate will feel. Once the foundation generates 
                                        sufficient funds, the Board of Directors will announce the program, procedure, scope, and strategies through 
                                        social media. Foundation will ask to submit an application with the evidence of the need, scope, and goal of 
                                        the individual to achieve after the degree they achieve. Foundation will frame a selection committee who will 
                                        find appropriate candidates to receive the fund. Foundation will pay tuition fees directly to the university 
                                        but send the cash for books, stationeries, fooding, travel, visa, and pocket money.
                                        </p>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="team-details__top-right">
                                        <div className="team-details__top-content">
                                            
                                            <h3 className="team-details__top-name">Activities</h3>
                                            {/* <div className="team-details__social">
                                                <a href="#"><i className="fab fa-twitter"></i></a>
                                                <a href="#"><i className="fab fa-facebook"></i></a>
                                                <a href="#"><i className="fab fa-pinterest-p"></i></a>
                                                <a href="#"><i className="fab fa-instagram"></i></a>
                                            </div> */}
                                            
                                            <p className="team-details__top-text-2">
                                            <img src={publicUrl+"assets/images/project/learnSans.png" } width={400} />
                                            </p>
                                            <p className="team-details__top-text-2">
                                            <img src={publicUrl+"assets/images/project/sanskrit.jpeg"} width={400} />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Team Details End */}

            </>
        )
    }
}