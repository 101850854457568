import React from 'react';

export default class YouthEmpowerment extends React.Component {
    componentDidMount() {

        const $ = window.$;

        if ($("#datepicker").length) {
            $("#datepicker").datepicker();
        }
        
        if ($("#datepicker2").length) {
            $("#datepicker2").datepicker();
        }
        
        if ($("#datepicker-inline").length) {
            $("#datepicker-inline").datepicker();
        }
        
        $('input[name="time"]').ptTimeSelect();
          
    }
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                {/* Team Details Start */}
                <section className="team-details">
                    <div className="container">
                        <div className="team-details__inner">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="team-details__top-left">
                                    <div className="team-details__top-content">
                                            <p className="team-details__top-title">Services:-</p>
                                            <h3 className="team-details__top-name">Youth Empowerment Program</h3>
                                            {/* <div className="team-details__social">
                                                <a href="#"><i className="fab fa-twitter"></i></a>
                                                <a href="#"><i className="fab fa-facebook"></i></a>
                                                <a href="#"><i className="fab fa-pinterest-p"></i></a>
                                                <a href="#"><i className="fab fa-instagram"></i></a>
                                            </div> */}
                                            <p className="team-details__top-text-1">
                                                Our Goal:    
                                            </p>
                                            
                                        </div>

                                        <div className="team-details__top-img">
                                        <p className="team-details__top-text-2">
                                        Teknath Timsina Welfare Foundation(TTWF) will support any geographical regions for the youth empowerment 
                                        program with the entrepreneur skills. These skills could be in the sector of agriculture, cattle rearing 
                                        (including goat rearing, sheep rearing, chicken raising, egg production, fishery, electricians, mechanical, 
                                        carpentry, sculpting, carving, IT, etc.). TTWF will fund the specific group of people especially that would 
                                        benefit the entire community/village/region. TTWF will look for the permanent independence, self-reliance, 
                                        viable, self-sustainable, leveraging the local resources. TTWF believes that “to bring development in the country, 
                                        you should develop regions. To develop the regions, you should develop the villages. To develop the villages, 
                                        you should bring development in the families”. Family is a building block of the nation. TTWF will focus on 
                                        strengthening the family with the entrepreneur skills bringing variation in the entire village creating the 
                                        entrepreneurial ecosystem. Once sufficient funds are generated, the Board of Directors of the foundation 
                                        will declare through the social media, and local government of the particular regions about the program, procedure, and scope.


                                            </p>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="team-details__top-right">
                                        <div className="team-details__top-content">
                                            
                                            <h3 className="team-details__top-name">Activities</h3>
                                            {/* <div className="team-details__social">
                                                <a href="#"><i className="fab fa-twitter"></i></a>
                                                <a href="#"><i className="fab fa-facebook"></i></a>
                                                <a href="#"><i className="fab fa-pinterest-p"></i></a>
                                                <a href="#"><i className="fab fa-instagram"></i></a>
                                            </div> */}
                                            
                                            <p className="team-details__top-text-2">
                                            <img src={publicUrl+"assets/images/project/youth.jpg"} alt="" />
                                            </p>
                                            <p className="team-details__top-text-2">
                                            <img src={publicUrl+"assets/images/project/support.jpg"} width={400} alt="" />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Team Details End */}

            </>
        )
    }
}