
export const getBaseApiUrl = () => {
    // const apiUrl = 'http://localhost:3010';
    const apiUrl = 'https://api.ttwf-us.org';
    return apiUrl;
  };

 export  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phoneNumber);
}
