import React from 'react';
import {Link} from "react-router-dom";

const Nav = () => {
    return (
        <div className="main-menu text-center">
            <nav>
                <ul className="main-menu__list">
                    <li className="dropdown">
                        <Link to={process.env.PUBLIC_URL + `/`}>Home</Link>
                        {/* <ul>
                            <li><Link to={process.env.PUBLIC_URL + `/`}>Home One</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/home-two`}>Home Two</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/home-three`}>Home Three</Link></li>
                            <li className="dropdown">
                                <Link to={process.env.PUBLIC_URL + `/`}>Header Styles</Link>
                                <ul>
                                    <li><Link to={process.env.PUBLIC_URL + `/`}>Header One</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + `/home-two`}>Header Two</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + `/home-three`}>Header Three</Link></li>
                                </ul>
                            </li>
                        </ul> */}
                    </li>
                    <li><Link to={process.env.PUBLIC_URL + `/about`}>About</Link></li>
                    <li className="dropdown">
                        <Link to={process.env.PUBLIC_URL + `#`}>Services</Link>
                        <ul>
                            <li><Link to={process.env.PUBLIC_URL + `/medical-education`}>Medical Education</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/youth-empowerment`}>Youth Empowerment</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/sanskrit-education`}>Sanskrit Education</Link></li>
                            {/* <li><Link to={process.env.PUBLIC_URL + `/project-details`}>Project Details</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/testimonials`}>Testimonial</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/gallery`}>Gallery</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/error`}>404 Error</Link></li> */}
                        </ul>
                    </li>
                    <li className="dropdown">
                        <Link to={process.env.PUBLIC_URL + `#`}>Membership</Link>
                        <ul>
                            <li><Link to={process.env.PUBLIC_URL + `/platinum-club`}>Platinum Club</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/gold-club`}>Gold Club</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/silver-club`}>Silver Club</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/power-club`}>Power Club</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/hero-club`}>Hero Club</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/general-membership`}>General Club</Link></li>
                        </ul>
                    </li>
                    <li><Link to={process.env.PUBLIC_URL + `/donor_profile`}>Donor Profile</Link></li>
                    <li><Link to={process.env.PUBLIC_URL + `/biography`}>Biography</Link></li>
                    
                    {/* <li className="dropdown">
                        <Link to={process.env.PUBLIC_URL + `#`}>Biography</Link>
                        <ul>
                            <li><Link to={process.env.PUBLIC_URL + `/blog-v-1`}>Blog V-1</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/blog-v-2`}>Blog V-2</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/blog-sidebar`}>Blog Sidebar</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/blog-details`}>Blog Details</Link></li>
                        </ul>
                    </li> */}
                    <li><Link to={process.env.PUBLIC_URL + `/contact`}>Contact</Link></li>
                </ul>
            </nav>
        </div>
    )
}

export default Nav;


