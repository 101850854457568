import React from 'react';
import HeaderOne from '../common/header/HeaderOne';
import LoginForm from '../components/user/LoginForm';

import FooterTwo from '../common/footer/FooterTwo';

const Contact = () => {
    return (
        <>
            <HeaderOne />
            <LoginForm />
            <FooterTwo />
        </>
    )
}

export default Contact;