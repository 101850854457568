import { useState, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
// import {useNavigate } from "react-router-dom";
// import { FaFilePdf  } from "react-icons/fa";
import { getBaseApiUrl } from '../utils/utility'


import HeaderOne from '../common/header/HeaderOne';
// import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import MBBSApplicationContent from '../components/medical-education/MBBSApplicationContent';
import MBBSApplicationAdmin from '../components/medical-education/MBBSApplicationAdmin';
import MBBSApplicationDownload from '../components/medical-education/MBBSApplicationDownload';



import FooterTwo from '../common/footer/FooterTwo';

const MBBSApplication = () => {
    const [cookies] = useCookies(['token']);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [me, setMe] = useState(null);

    useEffect(() => {
        // Check if the 'token' cookie exists
        const token = cookies['token'];
        setIsLoggedIn(!!token); // Set isLoggedIn based on the presence of the token
        if (isLoggedIn) {
          const fetchApplicantDetails = async () => {
            const headers = {
              'Authorization': `Bearer ${cookies['token']}`,
            };
            
            // Make a request to the server with the headers
            axios.get(`${getBaseApiUrl()}/me`, { headers })
              .then(response => {
                setMe(response.data)
              })
              .catch(error => {
                console.log(error);
              });
    
          }
        fetchApplicantDetails();
        }
      }, [cookies, isLoggedIn]);
    
      

    return (
        <>
            <HeaderOne />
            {/* <Breadcrumb 
                heading="Medical Education Program"
                currentPage="Medical Education" 
            /> */}
            {me && me.accountType === 'admin' && <MBBSApplicationAdmin me={me} isLoggedIn={isLoggedIn} />}
            {!me && <MBBSApplicationDownload />}
            {me && me.accountType === 'applicant' && <MBBSApplicationContent me={me} isLoggedIn={isLoggedIn} />}
            
            <FooterTwo />
        </>
    )
}

export default MBBSApplication;