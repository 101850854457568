import React, { useState, useRef} from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { getBaseApiUrl, validateEmail, validatePhoneNumber } from '../../utils/utility'
import axios from 'axios';

export default function ContactForm() {
    let publicUrl = process.env.PUBLIC_URL+'/';
    
    const [contact, setContact] = useState({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
        captchaValue: null,
    });
    
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const recaptchaRef = useRef();

    const setErrorMessage = (msg) => {
        setError(msg);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setContact((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    
    const handleCaptchaChange = (value) => {
        setContact((prevData) => ({ ...prevData, captchaValue: value }));
    };

    const validateForm = () => {
        if (!validateEmail(contact.email)) {
            setErrorMessage('Invalid Email');
            return false; 
        }
        if (!validatePhoneNumber(contact.phone)) {
            setErrorMessage('Invalid Phone');
            return false; 
        }
        // Basic validation, you can add more complex validation as needed
        if (!contact.name || !contact.email || !contact.phone || !contact.subject || !contact.message) {
          setErrorMessage('All fields are required.');
          return false;
        }
        if (contact.captchaValue === null) {
          setErrorMessage('Captcha is missing');
          return false;
        }
        return true;
      };
    
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            setSuccess(null);
            recaptchaRef.current.reset(); 
            return;
          }
       
        try {
            const response = await axios.post(`${getBaseApiUrl()}/contact`, contact);
            setSuccess(response.data.message);
            setErrorMessage('');
            recaptchaRef.current.reset(); // Reset reCAPTCHA on successful submission 
            setSubmitted(true);
            // navigate('/login')
          } catch (error) {
              console.error('Your message failed', error.message);
              setErrorMessage('Your message failed. Please try again.'); // Set the error message
              recaptchaRef.current.reset(); 
          }

        setError(null);
        recaptchaRef.current.reset(); 

    };

    

   
    
    return (
        <>
            <section className="contact-page">
                <div className="contact-page-bg" style={{backgroundImage: 'url('+publicUrl+'assets/images/backgrounds/contact-page-bg.jpg)'}}></div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6">
                            <div className="contact-page__left">
                                <div className="section-title text-left">
                                    <span className="section-title__tagline">Contact Us</span>
                                    <h2 className="section-title__title">Contact us using options below:</h2>
                                </div>
                                <div className="contact-page__form">

                                    {error && <div style={{border:'1px solid red', height:'50px', padding: '10px', marginBottom: '15px', color:'red'}}>
                                        <b>{error}</b>
                                    </div>}
                                    {success && <div style={{border:'1px solid green', height:'50px', padding: '10px', marginBottom: '15px', color:'green'}}>
                                        <b>{success}</b>
                                    </div>}
                                    
                                        <div className="row">
                                            <div className="col-xl-6">
                                                <div className="comment-form__input-box" style={{display: 'flex', alignItems: 'center'}}>
                                                    <input type="text" placeholder="Your name" name="name" 
                                                    value={contact.name} onChange={handleChange}
                                                    disabled={submitted}
                                                   />
                                                    <span style={{ color: 'red', display:'inline-block', marginTop:'-50px' }}>*</span>
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="comment-form__input-box" style={{display: 'flex', alignItems: 'center'}}>
                                                    <input type="email" placeholder="Email address" name="email"
                                                    value={contact.email} onChange={handleChange} 
                                                    disabled={submitted}
                                                     />
                                                    <span style={{ color: 'red', display:'inline-block', marginTop:'-50px' }}>*</span>
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="comment-form__input-box" style={{display: 'flex', alignItems: 'center'}}>
                                                    <input type="text" placeholder="Phone number" name="phone"
                                                    value={contact.phone} onChange={handleChange} 
                                                    disabled={submitted}
                                                     />
                                                    <span style={{ color: 'red', display:'inline-block', marginTop:'-50px' }}>*</span>
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="comment-form__input-box" style={{display: 'flex', alignItems: 'center'}}>
                                                    <input type="text" placeholder="Subject" name="subject"
                                                    value={contact.subject} onChange={handleChange} 
                                                    disabled={submitted}
                                                    />
                                                    <span style={{ color: 'red', display:'inline-block', marginTop:'-50px' }}>*</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="comment-form__input-box text-message-box" style={{display: 'flex', alignItems: 'center'}}>
                                                    <textarea name="message" placeholder="Write a comment"
                                                    value={contact.message} onChange={handleChange} 
                                                    disabled={submitted}
                                                    ></textarea>
                                                    <span style={{ color: 'red', display:'inline-block', marginTop:'-200px' }}>*</span>
                                                </div>
                                                
                                              
                                                <ReCAPTCHA
                                                    sitekey="6LejiGEpAAAAAHgnoK3Vy6aSzt4EQVbiYmT4G1Ql"
                                                    onChange={handleCaptchaChange}
                                                    ref={recaptchaRef}
                                                    />

                                                <div className="comment-form__btn-box">
                                                    <button type="submit" className="thm-btn comment-form__btn"
                                                    onClick={handleSubmit} disabled={submitted}>Send a message</button>
                                                </div>
                                            </div>
                                        </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6">
                            <div className="contact-page__right">
                                <div className="contact-page__img">
                                    <img src={publicUrl+"assets/images/resources/contact-page-img-1.jpg"} alt="" />
                                    <div className="contact-page__img-shape">
                                        <img src={publicUrl+"assets/images/shapes/contact-page-img-shape.png"} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
    
}