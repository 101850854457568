import React from 'react';
import {Link} from 'react-router-dom';

export default class FooterTwo extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <footer className="site-footer">
                    <div className="site-footer__top">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="100ms">
                                    <div className="footer-widget__column footer-widget__about">
                                        <div className="footer-widget__about-logo">
                                            <Link to={process.env.PUBLIC_URL + `/`}>
                                                <img src={publicUrl+"assets/images/resources/logo-ttwf-12.png"} alt="" />
                                                {/* <img src={publicUrl+"assets/images/resources/footer-logo.png"} alt="" /> */}
                                            </Link>
                                        </div>
                                        <ul className="list-unstyled footer-widget__list">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-back-in-time"></span>
                                                </div>
                                                <div className="text">
                                                    <p>Open Hours <br/>Mon - Fri: 8.00 am. - 5.00 pm.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-place"></span>
                                                </div>
                                                <div className="text">
                                                    <p>1016 Saffron Dr. Mechanicsburg, PA 17050</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-call"></span>
                                                </div>
                                                <div className="text">
                                                    <p><a href="tel:09969569535">717 836 0058</a></p>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="footer-widget__social">
                                            <a href="#"><i className="fab fa-facebook"></i></a>
                                            <a href="#"><i className="fab fa-twitter"></i></a>
                                            <a href="#"><i className="fab fa-pinterest-p"></i></a>
                                            <a href="#"><i className="fab fa-instagram"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="200ms">
                                    <div className="footer-widget__column footer-widget__service clearfix">
                                        <h3 className="footer-widget__title">Programs</h3>
                                        <ul className="footer-widget__service-list list-unstyled clearfix">
                                            <li><Link to={process.env.PUBLIC_URL + `/about`}>Medical Education</Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + `/about`}>Youth Empowerment</Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + `/about`}>Sankrit Education</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="300ms">
                                    <div className="footer-widget__column footer-widget__links clearfix">
                                        <h3 className="footer-widget__title">Quick Link</h3>
                                        <ul className="footer-widget__links-list list-unstyled clearfix">
                                            <li><Link to={process.env.PUBLIC_URL + `/donate-now`}>Donate</Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + `/about`}>Membership</Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + `/donation`}>About Us</Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + `/about`}>Contact Us</Link></li>
                                            {/* <li><Link to={process.env.PUBLIC_URL + `/about`}>Biography</Link></li> */}
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="300ms">
                                    <div className="footer-widget__column footer-widget__map clearfix">
                                        <h3 className="footer-widget__title">Our Maps</h3>
                                        <div className="footer-widget__map-box">
                                            <iframe
                                                title="Map"
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3057.0653408909163!2d-77.00154748428567!3d40.21703697896792!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c8ab7b7e7d3c6f%3A0xb5b45e24bfa20843!2s1016%20Saffron%20Dr%2C%20Mechanicsburg%2C%20PA%2017050!5e0!3m2!1sen!2sus!4v1642570831152!5m2!1sen!2sus"

                                                className="footer-widget-map__one" ></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="site-footer__bottom">
                        <div className="container">
                            <div className="site-footer__bottom-inner">
                                <p className="site-footer__bottom-text">© 2024 Copyright by <a href="https://xhrhub.com">xhrhub</a></p>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
        )
    }
}