import React from 'react';
import {Link} from 'react-router-dom';

export default class ProjectOne extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="project-one">
                    <div className="container">
                        <div className="section-title text-center">
                            <span className="section-title__tagline">Our Services</span>
                            <h2 className="section-title__title">We take pride in offering <br /> support and assistance to our community.</h2>
                        </div>
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-12 wow fadeInUp" data-wow-delay="100ms">
                                {/* Project One Single */}
                                <div className="project-one__single">
                                    <div className="project-one__img">
                                        <img src={publicUrl+"assets/images/project/doctor.jpg"} alt="" />
                                        <div className="project-one__content">
                                            <p className="project-one__sub-title">M.B.B.S Sponsorship</p>
                                            <h3 className="project-one__title">Educational Scholarship</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="200ms">
                                {/* Project One Single */}
                                <div className="project-one__single">
                                    <div className="project-one__img">
                                        <img src={publicUrl+"assets/images/project/cause.jpg"} alt="" />
                                        <div className="project-one__content">
                                            <p className="project-one__sub-title">youth Empowerment</p>
                                            <h3 className="project-one__title">Empowering Skills
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                {/* Project One Single */}
                                <div className="project-one__single">
                                    <div className="project-one__img">
                                        <img src={publicUrl+"assets/images/project/bidhya.jpg"} alt="" />
                                        <div className="project-one__content">
                                        <p className="project-one__sub-title">M.B.B.S Sponsorship</p>
                                            <h3 className="project-one__title">Educational Scholarship
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="300ms">
                                {/* Project One Single */}
                                <div className="project-one__single">
                                    <div className="project-one__img">
                                        <img src={publicUrl+"assets/images/project/support.jpg"} alt="" />
                                        <div className="project-one__content">
                                        <p className="project-one__sub-title">youth Empowerment</p>
                                            <h3 className="project-one__title">Empowering Skills
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                {/* Project One Single */}
                                <div className="project-one__single">
                                    <div className="project-one__img">
                                        <img src={publicUrl+"assets/images/project/kids.jpg"} alt="" />
                                        <div className="project-one__content">
                                            <p className="project-one__sub-title">Kids Education</p>
                                            <h3 className="project-one__title">Children Education</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}