import { useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import {useNavigate } from "react-router-dom";
import { getBaseApiUrl } from '../../utils/utility'



const LoginForm = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });
  const [, setCookie] = useCookies(['token']);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate ();

  
  // const environment = process.env.ENVIRONMENT || 'TEST';
  // const apiUrlBaseProd = process.env.API_BASE_URL_PROD || '';
  // const apiUrlBaseTest = process.env.API_BASE_URL_TEST || '';
  // const apiUrlBase = environment === 'PROD' ? apiUrlBaseProd : apiUrlBaseTest;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    
    // console.log(apiUrlBaseTmp)

    try {
      const response = await axios.post(`${getBaseApiUrl()}/login`, {
        username: formData.username,
        password: formData.password,
      });

      const { token } = response.data;
      // console.log(token)

      // Save the token to a cookie named 'token'
      setCookie('token', token, { path: '/' });

      // Redirect or perform any other actions after successful login
      setErrorMessage(''); // Set the error message
      console.log('Login successful!');
      navigate('/mbbs-application')
    } catch (error) {


      if (error.response && error.response.status === 409) {
        // HTTP status code 409 indicates a conflict, likely meaning the user already exists
        console.error('Email not verified yet:', error.response.data.message);
        setErrorMessage(error.response.data.message);
    } else {
      setErrorMessage('Invalid username or password. Please try again.'); 
      console.error('Login failed:', error.message);

    }


        
      // Handle login failure, show error message, etc.
    }
  };

  let publicUrl = process.env.PUBLIC_URL + '/';

  return (
    <>
      <section className="contact-page">
        <div className="contact-page-bg" style={{ backgroundImage: 'url(' + publicUrl + 'assets/images/backgrounds/contact-page-bg.jpg)' }}></div>
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <div className="contact-page__left">
                <div className="section-title text-left">
                  <span className="section-title__tagline">Login</span>
                </div>
                <div className="contact-page__form">
                  <form onSubmit={handleLogin} className="comment-one__form contact-form-validated">
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="comment-form__input-box">
                          <input type="text" placeholder="username" name="username" value={formData.username} onChange={handleChange} />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="comment-form__input-box">
                          <input type="password" placeholder="password" name="password" value={formData.password} onChange={handleChange} />
                        </div>
                      </div>
                    </div>
                    {errorMessage && (
                      <div className="row">
                        <div className="col-xl-12">
                          <p style={{ color: 'red' }}>{errorMessage}</p>
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="comment-form__btn-box">
                          <button type="submit" className="thm-btn comment-form__btn">Login</button>
                          <span style={{padding: 50}}>New user? <a href='/register'>Register</a></span>
                        </div>
                        <div style={{paddingTop: 20}}>Forgot Password? Click <a href="/forgot-password">here</a> to reset </div>
                        
                        <div style={{paddingTop: 20}}>Forgot Username? Click <a href="/forgot-username">here</a> to retrieve </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="contact-page__right">
                <div className="contact-page__img">
                  <img src={publicUrl + "assets/images/resources/contact-page-img-1.jpg"} alt="" />
                  <div className="contact-page__img-shape">
                    <img src={publicUrl + "assets/images/shapes/contact-page-img-shape.png"} alt="" />
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginForm;
