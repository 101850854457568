import React from 'react';
import {Link} from 'react-router-dom';

export default class GeneralContent extends React.Component {
    componentDidMount() {

        const $ = window.$;

        if ($(".video-popup").length) {
            $(".video-popup").magnificPopup({
                type: "iframe",
                mainClass: "mfp-fade",
                removalDelay: 160,
                preloader: true,
                fixedContentPos: false,
            });
        }
          
    }
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                {/* Project Details Start */}
                <section className="project-details" style={{marginBottom:'40px'}}>
                    <div className="container">
                        
                        <div className="project-details__bottom">
                            <div className="row">
                            <h3 className="project-details__title">Information</h3>
                                <div className="col-xl-12">
                                    <div className="project-details__text-box">
                                        <p className="project-details__text-1">
                                        Any individual, agency, organization, business entity that makes annual
                                        sponsorship of any amount less than $999 without any long-term commitment will receive this
                                        membership title.

                                        </p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Project Details End */}

                
            </>
        )
    }
}