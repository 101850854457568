import { useState, useRef } from 'react';
import HeaderOne from '../common/header/HeaderOne';
import axios from 'axios';
// import {useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import FooterTwo from '../common/footer/FooterTwo';
import { getBaseApiUrl } from '../utils/utility'
import ReCAPTCHA from "react-google-recaptcha";


const ResetPassword = ({ match }) => {
  const { token } = useParams();
  const [resetSuccessMessage, setResetSuccessMessage] = useState('');
  const [resetErrorMessage, setResetErrorMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const recaptchaRef = useRef();

  const [password, setPassword] = useState({
    newPassword: '',
    newPasswordConfirm: '',
    captchaValue: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPassword((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

 
  const handleCaptchaChange = (value) => {
    setPassword((prevData) => ({ ...prevData, captchaValue: value }));
    };
    const validateForm = () => {
      // Basic validation, you can add more complex validation as needed
      
      if (!password.newPassword || !password.newPasswordConfirm) {
        setResetErrorMessage('All Fields are required');
        return false;
      }
      
      if (password.newPassword !== password.newPasswordConfirm) {
        setResetErrorMessage('Password and confirm password did not match');
        return false;
      }

      if (!password.captchaValue) {
        setResetErrorMessage('Captcha is missing');
        return false;
      }
      return true;
    };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setResetSuccessMessage('');
    setResetErrorMessage('');
    if (!validateForm()) {
      recaptchaRef.current.reset();
      setResetSuccessMessage('');
      return;
    }

    if (!password.captchaValue) {
      setResetErrorMessage('Captcha is missing');
      return false;
    }
     

    try {
      // Send a request to the server to initiate the password reset process

      const response = await axios.post(`${getBaseApiUrl()}/reset-password/${token}`, password);
      setResetSuccessMessage(response.data.message);
      setResetErrorMessage('');
      setSubmitted(true);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setResetErrorMessage(error.response.data.message);
         
           } else {
            setResetErrorMessage("Error getting the username. Please try again.");
  
          }

              
          setResetSuccessMessage('')
      // setMessageError('Error initiating password reset. Please try again.');
      console.error('Getting the username failed:', error.message);
    }
    recaptchaRef.current.reset();
  };


  
  return (
    <>
    <HeaderOne />

    <section className="contact-page">
        <div className="contact-page-bg" style={{ backgroundImage: 'url(assets/images/backgrounds/contact-page-bg.jpg)' }}></div>
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <div className="contact-page__left">
                <div className="section-title text-left">
                  <span className="section-title__tagline">Password Reset ?</span>
                </div>
                <div className="contact-page__form">

                  

                {resetErrorMessage && <div style={{border:'1px solid red', height:'50px', padding: '10px', marginBottom: '15px', marginTop: '-35px', color:'red'}}>
                                        <b>{resetErrorMessage}</b>
                                    </div>}
                {resetSuccessMessage && <div style={{border:'1px solid green', height:'50px', padding: '10px', marginBottom: '15px', marginTop: '-35px', color:'green'}}>
                    <b>{resetSuccessMessage}</b>
                    <span style={{paddingLeft: 30}}> Click  <a href='/login'>here</a> to login </span>
                </div>}


                      
                    <form  onSubmit={handleFormSubmit}>
                        <div className="comment-form__input-box">
                        <input type="password" placeholder="New Password" value={password.newPassword} name='newPassword' onChange={handleChange} />
                        </div>
                        <div className="comment-form__input-box">
                        <input type="password" placeholder="New Password Confirm" value={password.newPasswordConfirm} name='newPasswordConfirm' onChange={handleChange} />
                        </div>


                        <ReCAPTCHA
                          sitekey="6LejiGEpAAAAAHgnoK3Vy6aSzt4EQVbiYmT4G1Ql"
                          onChange={handleCaptchaChange}
                          ref={recaptchaRef}
                        />

                        
                        <div className="comment-form__btn-box" style={{marginTop: 10}}>
                          <button type="submit" className="thm-btn comment-form__btn" disabled={submitted}>Submit</button>
                        </div>


                      
                       
                        </form>
                        <div style={{paddingTop: 20}}>Forgot Username? Click <a href="/forgot-username">here</a> to retrieve </div>
                        <div style={{paddingTop: 20}}>Login? Click <a href="/login">here</a> to login </div>
               
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="contact-page__right">
                <div className="contact-page__img">
                  <img src={"assets/images/resources/contact-page-img-1.jpg"} alt="" />
                  <div className="contact-page__img-shape">
                    <img src={"assets/images/shapes/contact-page-img-shape.png"} alt="" />
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>




    <FooterTwo />
    </>
   
  );
};

export default ResetPassword;
